import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";

export function useBookingCustomFieldDelete() {
  return useMutation({
    mutationFn: async (fieldId: string) => {
      return await api.deleteBookingCustomField({ fieldId });
    },
    invalidateKeys: [["booking-custom-fields"]],
    successMessage: "DELETE_SUCCESS",
  });
}
