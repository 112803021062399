import { api } from "@/lib/api-client";
import { useQuery } from "@tanstack/react-query";

export function useBookingCustomField(facilityId: string | undefined) {
  const query = useQuery({
    queryKey: ["booking-custom-fields", facilityId],
    queryFn: async () => {
      if (!facilityId) {
        return [];
      }
      return await api.getBookingCustomFields({
        facilityId,
      });
    },
    staleTime: 1000 * 60 * 60,
  });

  return {
    fields: query.data || [],
    isLoading: query.isLoading,
  };
}
