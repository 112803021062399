import { ApiObjects } from "@pulso/api-client";
import { useTranslation } from "react-i18next";

export function useUserRoleLabel() {
  const { t } = useTranslation();

  const labels: Record<ApiObjects["UserDto"]["role"], string> = {
    ADMIN: t("settings_users_role_admin", "Admin"),
    MANAGER: t("settings_users_role_manager", "Manager"),
    USER: t("settings_users_role_staff", "Staff"),
  };

  return labels;
}
