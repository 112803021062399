import { api } from "@/lib/api-client";
import { ApiObjects } from "@pulso/api-client";
import { useQuery } from "@tanstack/react-query";
import keyBy from "lodash/keyBy";

export function useProductsVariants(facilityId: string) {
  const query = useQuery({
    queryKey: ["products", "variants", facilityId],
    queryFn: async () => {
      const products = await api.getAllProductsWithVariantsForFacility({ facilityId });

      const variants: {
        productId: string;
        variantId: string;
        name: string;
        periods: Record<string, string>;
        thumbUrl: string;
        prices: ApiObjects["ProductVariantPriceDto"][];
      }[] = [];

      for (const product of products) {
        for (const variant of product.variants) {
          variants.push({
            productId: product.id,
            variantId: variant.id,
            name: `${product.name}${variant.variantName === "default" ? "" : ` - ${variant.variantName}`}`,
            periods: keyBy(variant.periods, (i) => i),
            thumbUrl: variant.photo.thumbUrl,
            prices: variant.prices,
          });
        }
      }

      return variants;
    },
  });

  return {
    variants: query.data || [],
    isLoading: query.isLoading,
  };
}
