import { useProducts } from "@/api/useProducts";
import { createForm } from "@/components/specific/Form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

const DEFAULT_OPTIONS = {
  allowEditingType: true,
  hideOptions: false,
  forBooking: false,
};

type OptionsType = Partial<typeof DEFAULT_OPTIONS> & {
  type?: "TEXT" | "OPTION" | "FILE" | "MULTILINE" | "DATE" | "PHONE";
};

export function useCustomerFieldsForm(options: OptionsType) {
  const opts = { ...DEFAULT_OPTIONS, ...options };
  const { t } = useTranslation();
  const { data: products } = useProducts();

  const formSchema = z.object({
    name: z.string().min(2).max(50),
    type: z.preprocess((r) => r || opts.type, z.enum(["TEXT", "OPTION", "FILE", "MULTILINE", "DATE", "PHONE"])),
    mandatory: z.boolean(),
    showInFastTrack: z.boolean(),
    showInStore: z.boolean(),
    options: z
      .union([z.array(z.string()), z.array(z.object({ value: z.string() }))])
      .transform((v) => v.map((a) => (typeof a === "string" ? a : a.value)))
      .refine((items) => !items.some((i) => !i), {
        message: t("common_error_emptyValues", "Empty values are not allowed"),
      })
      .refine((items) => !items.some((i) => i.length > 100), {
        message: t("common_error_maxLength", { max: 100 }),
      })
      .refine((items) => !items.some((i, idxI) => items.some((j, idxJ) => i === j && idxI !== idxJ)), {
        message: t("common_error_duplicateValues", "Duplicate values are not allowed"),
      })
      .optional(),
    forProductIds: z.array(z.string()),
  });

  const form = createForm(
    {
      name: {
        label: t("customerField_form_label_name", "Name"),
        type: "string",
      },
      type: {
        label: t("customerField_form_label_type", "Type"),
        type: "select",
        placeholder: "",
        disabled: !opts.allowEditingType,
        options: [
          { label: t("products_fields_form_option_text", "Text"), value: "TEXT" },
          { label: t("products_fields_form_option_options", "Options"), value: "OPTION" },
          ...(opts.forBooking ? [] : [{ label: t("customerFields_field_type_file", "File"), value: "FILE" }]),
          { label: t("customerFields_field_type_date", "Date"), value: "DATE" },
          { label: t("customerFields_field_type_multiLine", "Multi-line text"), value: "MULTILINE" },
        ],
      },
      ...(opts.forBooking
        ? {}
        : {
            mandatory: {
              label: t("customerField_form_label_mandatory", "Mandatory"),
              type: "switch",
              placeholder: t("customerField_form_checkbox_mandatory", "Require a value for every customer"),
            },
            showInFastTrack: {
              label: t("customerField_form_label_showInFastTrack", "Show in Fast Track"),
              type: "switch",
              placeholder: t(
                "customerField_form_checkbox_showInFastTrack",
                "This field will be show in the Fast Track"
              ),
            },
            showInStore: {
              label: t("customerField_form_label_showInStore", "Show in Online Store"),
              type: "switch",
              placeholder: t("customerField_form_checkbox_showInStore", "This field will be show in the Online Store"),
            },
          }),
      options: {
        label: t("customerField_form_label_options", "Options"),
        type: "items",
        hideIf: (values) => opts.hideOptions || (values.type && values.type !== "OPTION"),
        className: "lg:max-h-96 overflow-y-auto px-1",
      },
      forProductIds: {
        label: t("customerField_form_label_forProducts", "Associate with specific products"),
        type: "dropdown-multi",
        options:
          products?.map((p) => ({
            label: p.name,
            value: p.id,
          })) || [],
      },
    },
    formSchema
  );

  return form;
}
