import { useBookingFieldsUpdate } from "@/api/useBookingFieldsUpdate";
import { createForm, PulsoFormProvider } from "@/components/specific/Form";
import { transformFieldValuesToHash, transformHashToFieldValues } from "@/lib/utils";
import { ApiObjects } from "@pulso/api-client";
import { Button } from "@pulso/components/lib/Button";
import { FieldValue } from "@pulso/components/lib/FieldValue";
import { Nl2br } from "@pulso/components/lib/Nl2br";
import { zodPhone } from "@pulso/components/lib/PhoneInput";
import { zodFile } from "@pulso/components/utils/zodFile";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

type BookingDetailsFieldsProps = {
  booking: ApiObjects["BookingDto"];
  bookingCustomFields: ApiObjects["BookingCustomFieldDto"][];
};

export function BookingDetailsFields(props: BookingDetailsFieldsProps) {
  const { t } = useTranslation();
  const productIds = props.booking.items.map((p) => p.product.id);
  const updateMutation = useBookingFieldsUpdate(props.booking.id);
  const schema = z
    .object({
      ...props.bookingCustomFields
        .filter(
          (f) =>
            !f.forProductIds || f.forProductIds.length === 0 || f.forProductIds.some((pid) => productIds.includes(pid))
        )
        .reduce(
          (obj, field) => ({
            ...obj,
            ["fields_" + field.id]:
              field.type === "FILE"
                ? zodFile(t, { optional: true })
                : field.type === "PHONE"
                  ? zodPhone({ optional: true })
                  : z.string().max(100).optional(),
          }),
          {}
        ),
    })
    .transform((o) => {
      return {
        fields: transformHashToFieldValues<string | null | File | boolean>(o).map((v) =>
          typeof v.value === "object" && !(v.value instanceof File) && v.value !== null ? { ...v, value: "-" } : v
        ) as { id: string; value: string | null }[], // Hack :/
      };
    });

  const form = createForm(
    {
      ...props.bookingCustomFields
        .filter(
          (f) =>
            !f.forProductIds || f.forProductIds.length === 0 || f.forProductIds.some((pid) => productIds.includes(pid))
        )
        .reduce(
          (obj, field) => ({
            ...obj,
            ["fields_" + field.id]: {
              label: field.name,
              type:
                field.type === "FILE"
                  ? "file"
                  : field.type === "OPTION"
                    ? "select"
                    : field.type === "MULTILINE"
                      ? "text"
                      : field.type === "DATE"
                        ? "date-string"
                        : field.type === "PHONE"
                          ? "phone"
                          : "string",
              options: field.type !== "OPTION" ? undefined : field.options.map((v) => ({ label: v, value: v })),
              mandatory: false,
            },
          }),
          {}
        ),
    },
    schema
  );

  const [isEditing, setIsEditing] = useState(false);

  return (
    <div>
      {!isEditing && (
        <div className="absolute right-3 top-4">
          <Button size="sm" variant="link" onClick={() => setIsEditing(!isEditing)}>
            {t("common_button_edit", "Edit")}
          </Button>
        </div>
      )}
      {isEditing ? (
        <PulsoFormProvider
          {...form}
          isLoading={updateMutation.isPending}
          className="flex flex-col flex-1 overflow-y-hidden pb-6"
          fieldsClassName="flex-1 overflow-y-auto"
          onSubmit={(values) => updateMutation.mutate(values, { onSuccess: () => setIsEditing(false) })}
          initialValues={{
            ...transformFieldValuesToHash(
              props.booking.fields.map((f) => ({
                id: f.fieldId,
                value: f.value,
              }))
            ),
          }}
          extraButtons={
            <Button variant="outline" onClick={() => setIsEditing(false)}>
              {t("common_button_cancel")}
            </Button>
          }
        />
      ) : (
        <div className="grid grid-cols-2 gap-3 text-sm pb-6">
          {props.booking.fields
            .filter((field) => props.bookingCustomFields.find((f) => f.id === field.fieldId))
            .map((f) => (
              <div key={f.fieldId}>
                <div className="text-muted-foreground">
                  {props.bookingCustomFields.find((f2) => f2.id === f.fieldId)?.name}
                </div>
                <div>
                  <FieldValue type={f.type} value={f.value} />
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
