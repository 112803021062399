import { createForm, PulsoFormProvider } from "@/components/specific/Form";
import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";
import { ApiObjects } from "@pulso/api-client";
import { Spinner } from "@pulso/components/lib/Spinner";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { z } from "zod";

export function RedsysSettings(props: { facilityId: string }) {
  const { t } = useTranslation();

  const { data: settings, isLoading } = useQuery({
    queryKey: ["redsys-settings", props.facilityId],
    queryFn: () => api.getRedsysSettings({ facilityId: props.facilityId }),
  });

  const updateMutation = useMutation({
    mutationFn: (data: ApiObjects["UpdateRedsysSettingsBody"]) => {
      return api.updateRedsysSettings(
        {
          facilityId: props.facilityId,
        },
        data
      );
    },
    invalidateKeys: [["redsys-settings"]],
    successMessage: "SAVE_SUCCESS",
  });

  const schema = z.object({
    merchantCode: z.string(),
    secret: z.string(),
    terminal: z.string(),
    environment: z.enum(["TEST", "PRODUCTION"]),
  });
  const form = createForm(
    {
      environment: {
        type: "select",
        label: t("dashboard_redsys_form_environment", "Environment"),
        options: [
          { label: t("dashboard_redsys_form_environment_test", "Test"), value: "TEST" },
          { label: t("dashboard_redsys_form_environment_production", "Production"), value: "PRODUCTION" },
        ],
      },
      merchantCode: {
        type: "string",
        label: t("dashboard_redsys_form_merchantCode", "Merchant Code"),
        autoComplete: "new-password",
      },
      secret: {
        type: "string",
        label: t("dashboard_redsys_form_secretKey", "Secret"),
        autoComplete: "new-password",
      },
      terminal: {
        type: "string",
        label: t("dashboard_redsys_form_terminal", "Terminal"),
      },
    },
    schema
  );

  if (isLoading || !settings) {
    return <Spinner />;
  }

  return (
    <div className="w-48 lg:w-[700px]">
      <div className="pt-6 pb-3 font-bold">{t("dashboard_redsys_form_title", "Redsys settings")}</div>
      <PulsoFormProvider
        fieldsClassName="lg:grid lg:grid-cols-4"
        fields={form.fields}
        schema={form.schema}
        initialValues={{
          merchantCode: settings.merchantCode ?? "",
          secret: settings.secret ?? "",
          terminal: settings.terminal ?? "",
          environment: settings.environment ?? "TEST",
        }}
        onSubmit={async (values) => updateMutation.mutateAsync(values)}
        isLoading={updateMutation.isPending}
      />
    </div>
  );
}
