import React, { Suspense } from "react";
import "./App.css";
import "./setup/i18n";
import { AppRouter } from "./AppRouter";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "./lib/useAuth";
import { Toaster } from "sonner";
import { TooltipProvider } from "./components/ui/tooltip";
import { useZodDefaultErrors } from "@pulso/components/lib/useZodDefaultErrors";
import { SplashSpinner } from "./components/ui/splash-spinner";
import { useTranslation } from "react-i18next";
import { ConfirmProvider } from "@pulso/components/lib/useConfirm";

const queryClient = new QueryClient();

function App() {
  const { t } = useTranslation();
  useZodDefaultErrors(t);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <TooltipProvider>
            <ConfirmProvider>
              <Suspense fallback={<SplashSpinner />}>
                <AppRouter />
              </Suspense>
            </ConfirmProvider>
          </TooltipProvider>
        </AuthProvider>
      </QueryClientProvider>
      <Toaster
        className="flex justify-end"
        toastOptions={{
          unstyled: true,
          classNames: {
            toast: "p-3 border rounded-xl flex items-center text-sm bg-white shadow-xl w-full",
            title: "pl-2",
            error: "text-destructive border-destructive",
            success: "text-green-600 border-green-600",
            warning: "text-yellow-400",
            info: "text-blue-400",
          },
        }}
      />
    </>
  );
}

export default App;
