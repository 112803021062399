import { useUserRoleLabel } from "@/pages/settings/users/useUserRoleLabel";
import { ApiObjects } from "@pulso/api-client";
import { Badge } from "../ui/badge";

const ROLE_COLORS: Record<ApiObjects["UserDto"]["role"], string> = {
  ADMIN: "bg-red-200",
  USER: "bg-amber-200",
  MANAGER: "bg-blue-200",
};

export function UserRole(props: { role: ApiObjects["UserDto"]["role"] }) {
  const labels = useUserRoleLabel();

  return (
    <Badge variant="secondary" className={ROLE_COLORS[props.role]}>
      {labels[props.role]}
    </Badge>
  );
}
