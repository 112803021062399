import { createContext, PropsWithChildren, useContext, useState } from "react";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "./Dialog";
import { Button, ButtonProps } from "./Button";

type ConfirmDialogOptions = {
  title: string;
  content: string;
  onConfirm: () => void;
  onCancel?: () => void;
  confirmText?: string;
  cancelText?: string;
  confirmVariant?: ButtonProps["variant"];
  cancelVariant?: ButtonProps["variant"];
};

type DialogContextProps = {
  dialog: ConfirmDialogOptions | null;
  open: (dialog: ConfirmDialogOptions) => void;
  close: () => void;
};

const dialogContext = createContext<DialogContextProps>(null as any);

export function ConfirmProvider(props: PropsWithChildren) {
  const [dialog, setDialog] = useState<DialogContextProps["dialog"]>(null);

  function open(options: ConfirmDialogOptions) {
    setDialog(options);
  }

  function close() {
    setDialog(null);
  }

  return (
    <dialogContext.Provider value={{ dialog, open, close }}>
      {props.children}
      <Dialog open={!!dialog} onOpenChange={(isOpen) => !isOpen && close()}>
        <DialogContent hideFooter>
          <DialogHeader>
            <DialogTitle>{dialog?.title}</DialogTitle>
            <DialogDescription>{dialog?.content}</DialogDescription>
          </DialogHeader>
          <div className="flex flex-row-reverse gap-3">
            <Button
              onClick={() => {
                dialog?.onConfirm();
                close();
              }}
              variant={dialog?.confirmVariant || "default"}
            >
              {dialog?.confirmText || "OK"}
            </Button>
            <Button
              variant={dialog?.cancelVariant || "outline"}
              onClick={() => {
                close();
                dialog?.onCancel?.();
              }}
            >
              {dialog?.cancelText || "Close"}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </dialogContext.Provider>
  );
}

export function useConfirm() {
  const context = useContext(dialogContext);
  return context;
}
