import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";
import { ApiObjects } from "@pulso/api-client";

export function useUserRightDisable() {
  return useMutation({
    mutationFn: async (data: ApiObjects["UserRightsParams"]) => await api.disableUserRight(data, undefined),
    invalidateKeys: [["user-rights"]],
  });
}
