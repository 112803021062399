import React, { useEffect, useRef, useState } from "react";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { t, use } from "i18next";
import { Check, Edit2, GripVertical, Trash2 } from "lucide-react";
import { cn } from "@/lib/utils";
import { useDragList } from "./useDragList";
import { TooltipSimple } from "../ui/tooltip";

type ValueType = { id: string; value: string }[];

type ItemsInputProps = {
  value: ValueType;
  onChange(v: ValueType): void;
  itemClassName?: string;
  beforeDelete?: (option: { id: string; value: string }, execute: () => void) => void;
  extra?: (item: ValueType[0]) => React.ReactNode;
};

const ItemsInput = React.forwardRef<HTMLDivElement, ItemsInputProps>((props, ref) => {
  const [focusedPossibleNegative, setFocused] = useState(0);
  const focused = Math.abs(focusedPossibleNegative);
  const optionsDivRef = useRef<HTMLDivElement>(null);
  const [innerValue, setInnerValue] = useState(props.value);
  const { draggedValue, handles } = useDragList(innerValue, props.onChange);
  const [editingField, setEditingField] = useState<string | null>(null);
  const [editingValue, setEditingValue] = useState("");

  useEffect(() => {
    if (optionsDivRef.current) {
      const elementToFocus = optionsDivRef.current.children[focused];
      if (elementToFocus) {
        (elementToFocus.firstChild as HTMLInputElement).focus();
      }
    }
  }, [focusedPossibleNegative]);

  useEffect(() => {
    setInnerValue(props.value);
  }, [props.value]);

  return (
    <div ref={ref} className="space-y-3">
      <div ref={optionsDivRef} onDragOver={(e) => e.preventDefault()}>
        {draggedValue.map((item, index) => (
          <div key={item.id} className="flex items-center gap-2 mb-2">
            <div
              className={cn(
                "flex gap-1 items-center py-1 px-2 bg-gray-100 border border-gray-200 rounded-md",
                props.itemClassName
              )}
              {...handles(item.id, index)}
              onDoubleClick={() => {
                setEditingField(item.id);
                setEditingValue(item.value);
              }}
            >
              <GripVertical strokeWidth={1.2} className="flex-shrink-0" />
              {editingField === item.id ? (
                <Input
                  className="flex-1"
                  name={item.id}
                  value={editingValue}
                  onChange={(e) => setEditingValue(e.target.value)}
                  onBlur={updateWithEditedValue}
                  onKeyDown={onKeyDown}
                  autoFocus
                />
              ) : (
                <span className="flex-1 pl-4">{item.value}</span>
              )}
              {editingField === item.id ? (
                <TooltipSimple text={t("common_button_save", "Save")}>
                  <Button
                    type="button"
                    variant="ghost"
                    size="icon"
                    onClick={updateWithEditedValue}
                    tabIndex={-1}
                    disabled={editingValue === ""}
                  >
                    <Check strokeWidth={1.5} size={20} />
                  </Button>
                </TooltipSimple>
              ) : (
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  onClick={() => {
                    setEditingField(item.id);
                    setEditingValue(item.value);
                  }}
                  tabIndex={-1}
                >
                  <Edit2 strokeWidth={1.2} size={20} />
                </Button>
              )}
              <Button type="button" variant="ghost" size="icon" onClick={() => remove(item.id)} tabIndex={-1}>
                <Trash2 strokeWidth={1.2} size={20} />
              </Button>
            </div>
            <div>{props.extra?.(item)}</div>
          </div>
        ))}
      </div>
      <Button
        type="button"
        variant="secondary"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          add();
        }}
      >
        {t("common_button_add_options", "Add options")}
      </Button>
    </div>
  );

  function add(index?: number) {
    const newFieldId = "new_" + Math.random();
    if (typeof index === "number") {
      const newValue = [...innerValue];
      newValue.splice(index, 0, { id: newFieldId, value: "" });
      setInnerValue(newValue);
      setFocused(index);
    } else {
      setInnerValue([...innerValue, { id: newFieldId, value: "" }]);
      setFocused(innerValue.length);
    }
    setEditingField(newFieldId);
    setEditingValue("");
  }

  function update(id: string, value: string) {
    props.onChange(
      innerValue.map((v) => {
        if (v.id === id) {
          return { id, value };
        }
        return v;
      })
    );
  }

  async function remove(id: string) {
    if (props.beforeDelete) {
      const option = innerValue.find((v) => v.id === id);
      if (option) {
        props.beforeDelete(option, removeInner);
      }
    } else {
      removeInner();
    }

    function removeInner() {
      const newValue = innerValue.filter((v) => v.id !== id);
      setInnerValue(newValue);
      props.onChange(newValue);
    }
  }

  function updateWithEditedValue() {
    if (editingField && editingValue) {
      update(editingField, editingValue);
      setEditingField(null);
      setEditingValue("");
    }
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();

      if (editingField) {
        updateWithEditedValue();
      } else {
        const afterId = (e.target as HTMLInputElement).name;
        const afterIndex = props.value.findIndex((p) => p.id === afterId);
        add(afterIndex + 1);
      }
    }

    if ((e.key === "Delete" || e.key === "Backspace") && !(e.target as HTMLInputElement).value) {
      e.preventDefault();
      e.stopPropagation();
      remove((e.target as HTMLInputElement).name);

      if (e.key === "Backspace") {
        moveFocusUp();
      } else {
        setFocusSafe(-focusedPossibleNegative);
      }
    }

    if (e.key === "ArrowUp") {
      moveFocusUp();
    }

    if (e.key === "ArrowDown") {
      moveFocusDown();
    }
  }

  function moveFocusUp() {
    if (focused > 0) {
      setFocusSafe(focused - 1);
    }
  }

  function moveFocusDown() {
    setFocusSafe(focused + 1);
  }

  function setFocusSafe(f: number) {
    if (f > 0) {
      setFocused(Math.max(0, Math.min(props.value.length - 1, f)));
    } else {
      setFocused(-Math.max(0, Math.min(props.value.length - 1, -f)));
    }
  }
});
ItemsInput.displayName = "ItemsInput";

export { ItemsInput };
