import { formatDate } from "@pulso/utils";
import { ImagePreview } from "./ImagePreview";
import { useTranslation } from "react-i18next";
import { Nl2br } from "./Nl2br";

type FieldValueProps = {
  type: string;
  value: string | null;
  file?: {
    id: string;
    name: string;
    url: string;
  } | null;
};
export function FieldValue(props: FieldValueProps) {
  const { t } = useTranslation();
  return (
    <div>
      {props.file ? (
        <div>
          {props.file?.url && (
            <ImagePreview
              size="lg"
              url={props.file.url}
              name={t("customerInfo_fields_file_open_button", "Open file")}
            />
          )}
        </div>
      ) : props.type === "DATE" ? (
        props.value ? (
          formatDate(props.value, null, undefined, "date")
        ) : (
          "-"
        )
      ) : props.type === "MULTILINE" ? (
        <Nl2br text={props.value || ""} />
      ) : (
        <div>{props.value}</div>
      )}
    </div>
  );
}
