import { cn } from "@pulso/components/lib/utils";
import { ReactElement } from "react";
import { Link, NavLink } from "react-router-dom";

type MenuItem = { title: string | ReactElement; description: string; href: string; icon?: ReactElement };

export type SidebarMenuProps = {
  items: MenuItem[];
  wide?: boolean;
};
export function SidebarMenu(props: SidebarMenuProps) {
  return (
    <div
      className={cn(
        "min-w-32 h-full py-5 px-2 flex flex-col gap-1 text-sm bg-secondary-dark overflow-y-auto",
        props.wide ? "w-64" : "w-40"
      )}
    >
      {props.items.map((item) => (
        <NavLink
          key={item.href}
          to={item.href}
          className={({ isActive, isPending }) =>
            `rounded-md py-2 px-4 text-secondary-foreground flex items-center gap-1 ${isActive ? "bg-secondary-darker" : "hover:bg-secondary-darker"}`
          }
        >
          {item?.icon}
          {item.title}
        </NavLink>
      ))}
    </div>
  );
}
