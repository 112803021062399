import { UseTranslationResponse } from "react-i18next";
import { getPaymentMethodLabel } from "@pulso/utils";

export function InvoiceName(t: UseTranslationResponse<"", unknown>[0], type: string) {
  switch (type) {
    case "DISCOUNT":
      return t("bookings_invoice_discounts_form_reason_1", "Discount");
    case "DELAY":
      return t("bookings_invoice_penalties_form_option_delay", "Delayed return");
    case "DAMAGE":
      return t("bookings_invoice_penalties_form_option_damage", "Damaged item");
    case "LOST":
      return t("bookings_invoice_penalties_form_option_lost", "Lost item");
    case "OTHER":
      return t("bookings_invoice_penalties_form_option_other", "Other");
    default:
      return getPaymentMethodLabel(t, type);
  }
}
