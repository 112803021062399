import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { FileDown, Trash2 } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useBookingsFilter } from "../useBookingsFilter";
import { useEffect, useState } from "react";
import { Progress } from "@/components/ui/progress";
import { api } from "@/lib/api-client";
import flatten from "lodash/flatten";
import { ApiObjects } from "@pulso/api-client";
import { useGridState } from "./useGridState";
import { useColumnDefinitions } from "./useColumnDefinitions";
import { getTimezoneOffsetString } from "@pulso/utils";
import { getSortingFromGridStateAndColDefs } from "./sortingUtil";
import { TooltipSimple } from "@/components/ui/tooltip";
import { requiredColumns, communicationValidator } from "@/pages/reports/pages/exports/hospedajeColumns";
import jsonpath from "jsonpath";
import { useHospedajeSettings } from "@/api/useHospedajeSettings";
import { Link } from "react-router-dom";
import { Checkbox } from "@/components/ui/checkbox";
import { z } from "zod";
import sortBy from "lodash/sortBy";
import keyBy from "lodash/keyBy";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";

type ExportExcelButtonProps = {
  facilityId: string;
  settings: {
    enabled: boolean;
    mapping: Record<string, string>;
    products: string[];
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Comunicacion = any;

const colsByKey = keyBy(requiredColumns.columns, "key");

export function ExportHospedajeButton(props: ExportExcelButtonProps) {
  const { t } = useTranslation();
  const filter = useBookingsFilter();
  const [isOpen, setIsOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [progress, setProgress] = useState(0);
  const [bookings, setBookings] = useState<ApiObjects["BookingDto"][] | null>(null);
  const [comunicaciones, setComunicaciones] = useState<Comunicacion[] | null>(null);
  const [errors, setErrors] = useState<(z.ZodError | undefined)[]>([]);
  const isValid = errors.every((e) => !e);
  const [showAllBookings, setShowAllBookings] = useState(false);
  const [onlyExportCompleted, setOnlyExportCompleted] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { currentGridState } = useGridState(props.facilityId);
  const colDefs = useColumnDefinitions(props.facilityId);
  const { settings: hospedajeSettings } = useHospedajeSettings(props.facilityId);

  const bookingsWithError = sortBy(
    bookings?.map((b, i) => ({
      booking: b,
      error: errors[i],
      comunicacion: (comunicaciones || ([] as Comunicacion[]))[i],
    })),
    [(b) => (b.error ? -1 : 1), (b) => b.booking.reference]
  );

  useEffect(() => {
    setShowAllBookings(false);
  }, [isOpen]);

  return (
    <>
      <Dialog
        open={isOpen}
        onOpenChange={(isOpen) => {
          reset();
          setIsOpen(isOpen);
        }}
      >
        <DialogTrigger asChild>
          <div>
            <TooltipSimple text={"Exportar archivo para SES Hospedaje"}>
              <Button variant="outline">
                <FileDown size={16} />
              </Button>
            </TooltipSimple>
          </div>
        </DialogTrigger>
        <DialogContent hideFooter className="flex flex-col" size="4xl">
          <DialogHeader>
            <DialogTitle>{t("bookings_grid_export_hospedaje_title", "Export bookings")}</DialogTitle>
            <DialogDescription>
              {t(
                "bookings_grid_export_hospedaje_description",
                "The bookings you see on the screen are going to be exported in an XML file suitable for massive import in the system of SES Hospedaje."
              )}
            </DialogDescription>
          </DialogHeader>
          {!isExporting && !bookings && (
            <div>
              <div className="flex gap-1.5 items-center mb-3">
                <Checkbox
                  id="onlyExportCompleted"
                  checked={onlyExportCompleted}
                  onCheckedChange={(checked) => setOnlyExportCompleted(checked === true)}
                />
                <label htmlFor="onlyExportCompleted">
                  {t("bookings_grid_export_hospedaje_checkbox_onlyCompleted", "Only export completed bookings")}
                </label>
              </div>

              <Button onClick={onExport}>{t("bookings_grid_export_exportButton", "Export")}</Button>
            </div>
          )}
          {isExporting && <Progress value={progress} />}
          {bookings && (
            <div className="flex flex-col gap-4 flex-1 overflow-hidden">
              <div className="text-sm">{t("bookings_grid_export_ready", "Your export is ready.")}</div>
              <div className="text-sm font-bold">
                {t("bookings_grid_export_bookingsCount", "{{num}} bookings will be included in the export", {
                  num: bookings.length,
                })}
                {!isValid && (
                  <div className="text-destructive">
                    Se produjeron {errors.filter(Boolean).length} errores. Resuelvelos y reinicia la exportacíon.
                  </div>
                )}
              </div>
              <div className="flex-1 overflow-auto mb-3">
                {bookingsWithError.slice(0, showAllBookings ? 99999 : 3).map(({ booking, error, comunicacion }) => (
                  <div key={booking.id} className="flex items-center justify-between gap-3">
                    {error ? (
                      <Collapsible>
                        <CollapsibleTrigger asChild className="text-sm flex items-center gap-3 pb-1">
                          <div className="cursor-pointer hover:bg-secondary-light">
                            <Link
                              to={`/facility/${props.facilityId}/bookings/${booking.id}`}
                              target="_blank"
                              className="text-blue-700"
                            >
                              <div className="w-20">#{booking.reference}</div>
                            </Link>
                            <div>{error ? "❌" : "✅"}</div>
                            <div>{booking.customer.name}</div>
                            <div className="text-destructive">{error.issues.length} errores</div>
                          </div>
                        </CollapsibleTrigger>
                        <CollapsibleContent className="pl-6 pb-6">
                          {getErrorMessage(error, comunicacion)}
                        </CollapsibleContent>
                      </Collapsible>
                    ) : (
                      <div className="text-sm flex items-center gap-3 pb-1">
                        <Link
                          to={`/facility/${props.facilityId}/bookings/${booking.id}`}
                          target="_blank"
                          className="text-blue-700"
                        >
                          <div className="w-20">#{booking.reference}</div>
                        </Link>
                        <div>{error ? "❌" : "✅"}</div>
                        <div>{booking.customer.name}</div>
                      </div>
                    )}
                    <Button size="icon" variant="ghost" onClick={() => removeBooking(booking.id)}>
                      <Trash2 size={16} strokeWidth={1.2} />
                    </Button>
                  </div>
                ))}
                {!showAllBookings && bookings.length > 3 && (
                  <Button variant="link" className="px-0" onClick={() => setShowAllBookings(true)}>
                    {t("bookings_grid_export_showMoreButton", "+ {{num}} more", {
                      num: bookings.length - 3,
                    })}
                  </Button>
                )}
              </div>
              <div>
                {isValid ? (
                  <Button onClick={downloadExportedFile}>{t("common_button_download", "Download")}</Button>
                ) : (
                  <Button onClick={onExport}>Reiniciar</Button>
                )}
              </div>
              {error && <div className="text-destructive">{error}</div>}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
  function reset() {
    setIsExporting(false);
    setProgress(0);
    setBookings(null);
  }

  async function onExport() {
    reset();
    setIsExporting(true);

    const bookings = await collectAllBookings();
    const communications = getCommuncationsFromBookings(bookings);
    const errors = getErrors(communications);
    setErrors(errors);
    setComunicaciones(communications);
    setBookings(bookings);
    setIsExporting(false);
  }

  function getErrors(communications: Comunicacion[]): (z.ZodError | undefined)[] {
    return communications.map((c) => {
      try {
        communicationValidator.parse(c);
      } catch (e) {
        return e as z.ZodError;
      }
    });
  }

  async function collectAllBookings() {
    const pages = [];
    const pageSize = 25;
    let page = 1;
    let totalPages = 1;
    while (page <= totalPages) {
      const bookingsPage = await api.getBookings({
        facilityId: props.facilityId,
        ...filter.forApi(page),
        ...getSortingFromGridStateAndColDefs(currentGridState, colDefs),
        pageSize,
        range: currentGridState.rangeType ?? "fullBooking",
      });
      totalPages = Math.ceil(bookingsPage.total / pageSize);
      pages.push(bookingsPage.bookings);
      page++;
      setProgress(((page - 1) / totalPages) * 100);
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    return flatten(pages)
      .filter((b) => b.items.some((i) => props.settings.products.includes(i.product.id)))
      .filter((p) =>
        onlyExportCompleted ? p.status === "COMPLETED" : p.status !== "CANCELLED" && p.status !== "ON_HOLD"
      );
  }

  async function downloadExportedFile() {
    if (!comunicaciones) {
      return;
    }

    try {
      await downloadXMLFile(comunicaciones);
    } catch (e: unknown) {
      if (typeof e === "object" && e && "message" in e && typeof e.message === "string") {
        setError(e.message);
        // console.log(e);
      }
    }
  }

  function getCommuncationsFromBookings(bookings: ApiObjects["BookingDto"][]): Comunicacion[] {
    const mapping = props.settings.mapping || {};

    const communications = bookings.map((booking) => {
      return requiredColumns.columns.reduce((acc, field) => {
        const expr = mapping[field.key];
        if (expr) {
          let value = expr.startsWith("$") ? jsonpath.query(booking, expr)[0] : expr;

          if (!value) {
            if (expr === "$.deliveredAt") {
              value = jsonpath.query(booking, "$.startAt")[0];
            } else if (expr === "$.returnedAt") {
              value = jsonpath.query(booking, "$.endAt")[0];
            }
          }
          value = (value || "").trim();
          if (value) {
            set(acc, field.key, formatValue(booking, value, field.format));
          }
        }
        return acc;
      }, {} as Comunicacion);
    });

    communications.forEach((item) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      item.persona.forEach((persona: any, i: number) => {
        const rol = i === 0 ? "TI" : i === 1 ? "CP" : "CS";
        persona.datosPersona = { rol, ...persona.datosPersona };
      });
    });

    return communications;
  }

  function formatValue(booking: ApiObjects["BookingDto"], value: string, format?: string): string {
    if (format && value) {
      switch (format) {
        case "dateTime":
          return (value || "").substring(0, 19) + ".000" + getTimezoneOffsetString(value, booking.timezone);
        case "date":
          return (value || "").substring(0, 10) + "+01:00";
        case "tipoDocumento":
          return mapValue(value, "tipoDocumento", hospedajeSettings?.valueMapping);
        case "pais":
          return mapValue(value, "pais", hospedajeSettings?.valueMapping);
        case "tipoPago":
          return mapValue(value, "tipoPago", {
            tipoPago: {
              CASH: "EFECT",
              CARD: "TARJT",
              TRANSFER: "TRANS",
              PAYPAL: "PLATF",
              STRIPE: "PLATF",
              REDSYS: "PLATF",
            },
          });
      }
    }

    return value;

    function mapValue(
      value: string,
      mappingKey: string,
      mapping?: Record<string, Record<string, string>> | undefined
    ): string {
      const valueMapping = mapping?.[mappingKey];
      if (!valueMapping) {
        return value;
      }

      return valueMapping[value] || value;
    }
  }

  async function downloadXMLFile(data: Comunicacion[]) {
    const xmlContent = await getRegistroXml(data);
    const blob = new Blob([xmlContent], { type: "text/xml" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    const { from, to } = filter.forApi(1);
    const fromStr = Intl.DateTimeFormat("es-ES", { day: "numeric", month: "short" }).format(new Date(from));
    const toStr = Intl.DateTimeFormat("es-ES", { day: "numeric", month: "short" }).format(new Date(to));
    a.download = `reservas-de-${fromStr}-a-${toStr}.xml`.replace(/\s/g, "-");
    a.click();
  }

  async function getRegistroXml(comunicaciones: Comunicacion[]): Promise<string> {
    const convert = await import("xml-js");

    const xml = convert.js2xml(
      { solicitud: { comunicacion: comunicaciones } },
      {
        compact: true,
        ignoreComment: true,
        spaces: 2,
      }
    );

    return `<?xml version="1.0" encoding="UTF-8"?>
<ns2:peticion xmlns:ns2="http://www.neg.hospedajes.mir.es/altaAlquilerVehiculo">
  ${xml}
</ns2:peticion>
`;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function set(obj: any, path: string, value: any) {
    // Split the path into an array of keys
    const keys = path
      .replace(/\[([^[\]]*)\]/g, ".$1") // Convert array indices to dot notation
      .split(".")
      .filter((key) => key); // Remove any empty keys resulting from leading dots

    // Iterate through the keys to reach the target property
    keys.reduce((acc, key, index) => {
      // If we're at the last key, set the value
      if (index === keys.length - 1) {
        acc[key] = value;
      } else {
        // If the key doesn't exist or isn't an object, create an empty object
        if (!acc[key] || typeof acc[key] !== "object") {
          // Determine if the next key is a number to create an array instead of an object
          acc[key] = /^\d+$/.test(keys[index + 1]) ? [] : {};
        }
      }
      return acc[key];
    }, obj);
  }

  function getErrorMessage(error: z.ZodError, com: Comunicacion) {
    const extraLabels: Record<string, string> = {
      "contrato.pago": "Pago",
      "persona[0].datosPersona": "Titular",
      "persona[1].datosPersona": "Conductor principal",
      "persona[2].datosPersona": "Conductor secundario",
      "persona[0].datosPersona.direccion": "Dirección titular",
      "persona[1].datosPersona.direccion": "Dirección conductor principal",
      "persona[2].datosPersona.direccion": "Dirección conductor secundario",
      "persona[0].permisoConducir": "Permiso de conducir titular",
      "persona[1].permisoConducir": "Permiso de conducir conductor principal",
      "persona[2].permisoConducir": "Permiso de conducir conductor secundario",
    };

    return error.issues.map((issue, i) => {
      const colKey = reconstructPath(issue.path);
      const label = colsByKey[colKey]?.label ?? extraLabels[colKey] ?? colKey;
      return (
        <div key={i} className="flex gap-2 items-center border-b py-1 text-sm">
          <div className="w-72 flex-shrink-0">{label}</div>
          <div className="w-48 flex-shrink-0">{val(jsonpath.query(com, colKey)[0])}</div>
          <div className="flex-1">{issue.message}</div>
        </div>
      );
    });

    function val(v: object | string) {
      return typeof v === "object" ? "" : v;
    }
  }

  function reconstructPath(path: (string | number)[]) {
    let p = "";
    for (const prop of path) {
      if (typeof prop === "number") {
        p += `[${prop}]`;
      } else {
        p += `.${prop}`;
      }
    }
    return p.substring(1);
  }

  function removeBooking(bookingId: string) {
    if (!bookings) {
      return;
    }

    const index = bookings.findIndex((b) => b.id === bookingId);
    if (index >= 0) {
      setBookings([...bookings.slice(0, index), ...bookings.slice(index + 1)]);
      setErrors([...errors.slice(0, index), ...errors.slice(index + 1)]);
      setComunicaciones([...(comunicaciones || []).slice(0, index), ...(comunicaciones || []).slice(index + 1)]);
    }
  }
}
