import { Outlet, useLocation } from "react-router-dom";
import { PropsWithChildren } from "react";
import { Separator } from "@/components/ui/separator";
import { SidebarMenu, SidebarMenuProps } from "@/components/specific/SidebarMenu";

type LayoutWithSidebarProps = PropsWithChildren<{
  items: SidebarMenuProps["items"];
  wide?: boolean;
}>;

export function LayoutWithSidebar(props: LayoutWithSidebarProps) {
  const location = useLocation();
  const activeItem = props.items.find((i) => i.href === location.pathname) ?? props.items[0];

  return (
    <div className="flex h-full">
      <SidebarMenu items={props.items} wide={props.wide} />
      <div className="flex-1 h-full overflow-y-auto">
        <div className="h-full">
          <div className="p-6">
            <div className="space-y-0.5">
              <h2 className="text-2xl font-bold tracking-tight">{activeItem?.title}</h2>
              <p className="text-muted-foreground">{activeItem?.description}</p>
            </div>
            <Separator className="my-6" />
            {props.children}
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
