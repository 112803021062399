import { Spinner } from "@/components/ui/spinner";
import { CustomerFieldCreateDialog } from "./CustomerFieldCreateDialog";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { useTranslation } from "react-i18next";
import { ApiObjects } from "@pulso/api-client";
import { ConfirmDialog } from "@/components/ui/confirm-dialog";
import { Button } from "@/components/ui/button";
import { ArrowDown, ArrowUp, Check, EditIcon, Trash2Icon } from "lucide-react";
import { CustomerFieldUpdateDialog } from "./CustomerFieldUpdateDialog";
import { cn } from "@/lib/utils";
import { Card, CardContent } from "@/components/ui/card";
import { InfoBox } from "@/components/specific/InfoBox";
import { PropsWithChildren } from "react";

type CustomerFieldsListProps = PropsWithChildren<{
  facilityId: string;
  fields: ApiObjects["CustomerFieldDto"][];
  isLoading: boolean;
  onCreate: (data: ApiObjects["CustomerFieldCreateBody"], onSuccess: () => void) => void;
  onDelete: (id: string) => void;
  onUpdate: (field: { id: string } & ApiObjects["CustomerFieldUpdateBody"], onSuccess?: () => void) => void;
  isCreating: boolean;
  isUpdating: boolean;
  forBooking?: boolean;
}>;

export function CustomFieldsList(props: CustomerFieldsListProps) {
  const { t } = useTranslation();

  const FIELD_TYPE_LABELS: Record<ApiObjects["CustomerFieldDto"]["type"], string> = {
    TEXT: t("products_fields_form_option_text"),
    OPTION: t("products_fields_form_option_options"),
    FILE: t("customerFields_field_type_file", "File"),
    MULTILINE: t("customerFields_field_type_multiLine", "Multi-line text"),
    DATE: t("customerFields_field_type_date", "Date"),
    PHONE: t("customerFields_field_type_phone", "Phone"),
  };

  if (props.isLoading || !props.facilityId) {
    return <Spinner />;
  }

  return (
    <>
      <CustomerFieldCreateDialog
        isCreating={props.isCreating}
        onCreate={props.onCreate}
        forBooking={props.forBooking}
      />
      {props.fields.length > 0 ? (
        <Table className="mt-6">
          <TableHeader>
            <TableRow>
              <TableHead>{t("customerField_form_label_name", "Name")}</TableHead>
              <TableHead>{t("customerField_form_label_type", "Type")}</TableHead>
              {!props.forBooking && (
                <>
                  <TableHead>{t("customerField_form_label_mandatory", "Mandatory")}</TableHead>
                  <TableHead className="text-center">
                    {t("customerField_form_label_showInFastTrack", "Fast Track")}
                  </TableHead>
                  <TableHead className="text-center">
                    {t("customerField_form_label_showInStore", "Online Store")}
                  </TableHead>
                </>
              )}
              <TableHead>
                <div className="flex gap-1">
                  {t("customerField_form_label_forProducts", "Products")}{" "}
                  <InfoBox
                    text={
                      props.forBooking
                        ? t(
                            "bookingField_form_label_forProducts_info",
                            "The field will only be included with bookings that have certain products."
                          )
                        : t(
                            "customerField_form_label_forProducts_info",
                            "Fields can be restricted to certain products only. Then clients will be asked to fill in those fields only if they are going to rent the associated products."
                          )
                    }
                  />
                </div>
              </TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {props.children}
            {props.fields.map((field) => (
              <TableRow key={field.id}>
                <TableCell>{field.name}</TableCell>
                <TableCell>{FIELD_TYPE_LABELS[field.type]}</TableCell>
                {!props.forBooking && (
                  <>
                    <TableCell>
                      {field.mandatory ? t("common_mandatory", "Mandatory") : t("common_optional", "Optional")}
                    </TableCell>
                    <TableCell>
                      <div className="flex justify-center items-center">
                        {field.showInFastTrack && <Check size={14} />}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="flex justify-center items-center">{field.showInStore && <Check size={14} />}</div>
                    </TableCell>
                  </>
                )}
                <TableCell>
                  {field.forProductIds.length ? (
                    <span className="text-warn flex gap-1 items-center flex-nowrap">
                      {t("customerField_settings_forProducts_someProducts_label", "{{count}} product(s)", {
                        count: field.forProductIds.length,
                      })}
                      <InfoBox
                        text={
                          props.forBooking
                            ? t(
                                "bookingField_form_label_forProducts_someProducts_info",
                                "The field will only be included with bookings that have any of the {{count}} associated product(s).",
                                {
                                  count: field.forProductIds.length,
                                }
                              )
                            : t(
                                "customerField_form_label_forProducts_someProducts_info",
                                "The field will only be shown to clients that are renting the {{count}} associated product(s).",
                                {
                                  count: field.forProductIds.length,
                                }
                              )
                        }
                      />
                    </span>
                  ) : (
                    t("customerField_settings_forProducts_allProducts_label", "All products")
                  )}
                </TableCell>
                <TableCell className="text-nowrap flex-nowrap">
                  <Button
                    variant="ghost"
                    onClick={() => props.onUpdate({ ...field, position: field.position - 1 })}
                    className={cn(field.position <= 1 && "invisible")}
                  >
                    <ArrowUp strokeWidth={1.2} />
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={() => props.onUpdate({ ...field, position: field.position + 1 })}
                    className={cn(field.position >= props.fields.length && "invisible")}
                  >
                    <ArrowDown strokeWidth={1.2} />
                  </Button>

                  <CustomerFieldUpdateDialog
                    field={field}
                    isUpdating={props.isUpdating}
                    onUpdate={props.onUpdate}
                    forBooking={props.forBooking}
                  >
                    <Button variant="ghost">
                      <EditIcon strokeWidth={1.2} />
                    </Button>
                  </CustomerFieldUpdateDialog>
                  <ConfirmDialog
                    title={t("customerFields_delete_title", "Delete {{field}}", { field: field.name })}
                    description={t(
                      "customerFields_delete_description",
                      "Are you sure you want to delete this customer field?"
                    )}
                    onContinue={() => props.onDelete(field.id)}
                  >
                    <Button variant="ghost">
                      <Trash2Icon strokeWidth={1.2} />
                    </Button>
                  </ConfirmDialog>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <div></div>
      )}
    </>
  );
}
