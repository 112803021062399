import { useCustomerFields } from "@/api/useCustomerFields";
import { useParams } from "react-router-dom";
import { useCustomerFieldsDelete } from "@/api/useCustomerFieldsDelete";
import { useCustomerFieldsUpdate } from "@/api/useCustomerFieldsUpdate";
import { CustomFieldsList } from "./CustomFieldsList";
import { Spinner } from "@pulso/components/lib/Spinner";
import { TableCell, TableRow } from "@/components/ui/table";
import { Check, EditIcon } from "lucide-react";
import { Button } from "@pulso/components/lib/Button";
import { useTranslation } from "react-i18next";
import { useCustomerFieldsCreate } from "@/api/useCustomerFieldsCreate";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { BookingCustomFieldsList } from "../booking-custom-fields/BookingCustomFieldsList";

export function CustomerFieldsPage() {
  const { t } = useTranslation();
  const { facilityId } = useParams();
  const { fields, isLoading } = useCustomerFields(facilityId);
  const createMutation = useCustomerFieldsCreate(facilityId);
  const deleteField = useCustomerFieldsDelete();
  const updateField = useCustomerFieldsUpdate();

  if (!facilityId) {
    return <Spinner />;
  }

  return (
    <div>
      <Card>
        <CardHeader>
          <CardTitle>{t("settings_customerFields_title", "Customer fields")}</CardTitle>
          <CardDescription>
            {t(
              "settings_customerFields_description",
              "Add extra information you would like to know about your customers"
            )}
          </CardDescription>
        </CardHeader>
        <CardContent>
          <CustomFieldsList
            facilityId={facilityId}
            fields={fields}
            isLoading={isLoading}
            onCreate={(data, onSuccess) => createMutation.mutate(data, { onSuccess })}
            onDelete={deleteField.mutate}
            onUpdate={(data, onSuccess) => updateField.mutate(data, { onSuccess })}
            isCreating={createMutation.isPending}
            isUpdating={updateField.isPending}
          >
            <>
              <TableRow>
                <TableCell>{t("customer_form_label_firstname")}</TableCell>
                <TableCell>{t("products_fields_form_option_text")}</TableCell>
                <TableCell>{t("common_mandatory", "Mandatory")}</TableCell>
                <TableCell>
                  <div className="flex justify-center items-center">
                    <Check size={14} />
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex justify-center items-center">
                    <Check size={14} />
                  </div>
                </TableCell>
                <TableCell>
                  <Button variant="ghost" className="invisible">
                    <EditIcon strokeWidth={1.2} />
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("customer_form_label_lastname")}</TableCell>
                <TableCell>{t("products_fields_form_option_text")}</TableCell>
                <TableCell>{t("common_mandatory", "Mandatory")}</TableCell>
                <TableCell>
                  <div className="flex justify-center items-center">
                    <Check size={14} />
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex justify-center items-center">
                    <Check size={14} />
                  </div>
                </TableCell>
                <TableCell>
                  <Button variant="ghost" className="invisible">
                    <EditIcon strokeWidth={1.2} />
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("customer_form_label_email")}</TableCell>
                <TableCell>{t("products_fields_form_option_text")}</TableCell>
                <TableCell>{t("common_optional", "Optional")}</TableCell>
                <TableCell>
                  <div className="flex justify-center items-center">
                    <Check size={14} />
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex justify-center items-center">
                    <Check size={14} />
                  </div>
                </TableCell>
                <TableCell>
                  <Button variant="ghost" className="invisible">
                    <EditIcon strokeWidth={1.2} />
                  </Button>
                </TableCell>
              </TableRow>
            </>
          </CustomFieldsList>
        </CardContent>
      </Card>
      <Card className="mt-6">
        <CardHeader>
          <CardTitle>{t("settings_bookingCustomFields_title", "Booking fields")}</CardTitle>
          <CardDescription>
            {t("settings_bookingCustomFields_description", "Add fields that are going to be attached to a booking")}
          </CardDescription>
        </CardHeader>
        <CardContent>
          <BookingCustomFieldsList facilityId={facilityId} />
        </CardContent>
      </Card>
    </div>
  );
}
