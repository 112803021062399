import { DragEvent, useEffect, useState } from "react";

export function useDragList<T extends { id: string }[]>(items: T, onChange: (v: T) => void) {
  const [draggedValue, setDraggedValue] = useState(items);
  const [draggedId, setDraggedId] = useState("");

  useEffect(() => {
    setDraggedValue(items);
  }, [items]);

  return {
    draggedValue,
    handles: (itemId: string, index: number) => ({
      draggable: true,
      onDragStart: (e: DragEvent) => {
        const element = e.target as HTMLElement;
        setDraggedId(itemId);
        requestAnimationFrame(function () {
          element.classList.add("dragging");
        });
      },
      onDragOver: (e: DragEvent) => {
        e.preventDefault();
        const itemToMoveIndex = draggedValue.findIndex((v) => v.id === draggedId);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newValue: any = [...draggedValue];
        const [removed] = newValue.splice(index, 1);
        newValue.splice(itemToMoveIndex, 0, removed);
        setDraggedValue(newValue);
      },
      onDragEnd: (e: DragEvent) => {
        onChange(draggedValue);
        (e.target as HTMLElement).classList.remove("dragging");
      },
    }),
  };
}
