import { TooltipSimple } from "@/components/ui/tooltip";
import { ArrowDown, ArrowUp, Dot, HandMetal, Plus, Trash2 } from "lucide-react";
import { Link, Outlet, useMatch, useNavigate, useParams } from "react-router-dom";
import { NewProductModal } from "./NewProductModal";
import { useProducts } from "@/api/useProducts";
import { ConfirmDialog } from "@/components/ui/confirm-dialog";
import { useProductDelete } from "@/api/useProductDelete";
import { Button } from "@/components/ui/button";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ApiObjects } from "@pulso/api-client";
import { cn } from "@/lib/utils";
import { withFacilityIdParam } from "@/lib/withParams";
import { useProductReorder } from "@/api/useProductsReorder";
import { useTranslation } from "react-i18next";
import { PageHeader } from "@/components/specific/PageHeader";
import { atomWithStorage } from "jotai/utils";
import { useAtom } from "jotai";
import sortBy from "lodash/sortBy";

export const ProductsPage = withFacilityIdParam(function ({ facilityId }) {
  const productIdAtom = useMemo(() => atomWithStorage<string>("productsPageProductId_" + facilityId, ""), []);

  const params = useParams();
  const [storedProductId, setStoredProductId] = useAtom(productIdAtom);
  const productId = params.productId || storedProductId;
  const { t } = useTranslation();
  const allProducts = useProducts();
  const currentProduct = allProducts.data?.find((p) => p.id === productId);

  const subPageMatch = useMatch("/facility/:facilityId/products/:productId/:subpage");
  const subPage = subPageMatch?.params.subpage || "inventory";
  const products = allProducts.data?.filter((p) => !p.isComplement) ?? [];
  const complements = allProducts.data?.filter((p) => p.isComplement) ?? [];
  const navigate = useNavigate();
  const deleteProduct = useProductDelete();
  const reorderProducts = useProductReorder(facilityId);

  useEffect(() => {
    if (params.productId) {
      setStoredProductId(params.productId);
    }

    if (!params.productId && storedProductId && products.some((p) => p.id === storedProductId)) {
      navigate(`/facility/${facilityId}/products/${storedProductId}/${subPage}`);
    }
  }, [params.productId, storedProductId]);

  const submenuItems = [
    { label: t("products_submenu_inventory", "Inventory"), link: "inventory", icon: <Dot /> },
    { label: t("products_submenu_details", "Details"), link: "details", icon: <Dot /> },
    {
      label: t("products_submenu_fields", "Variants"),
      link: "fields",
      icon: <Dot />,
      hideIf: (product: ApiObjects["ProductDto"]) => product.isPackage,
    },
    {
      label: t("products_submenu_package", "Package"),
      link: "package",
      icon: <Dot />,
      hideIf: (product: ApiObjects["ProductDto"]) => !product.isPackage,
    },
    { label: t("products_submenu_pricing", "Pricing"), link: "pricing", icon: <Dot /> },
    {
      label: t("products_submenu_complements", "Complements"),
      link: "complements",
      icon: <Dot />,
      hideIf: (product: ApiObjects["ProductDto"]) => product.isComplement,
    },
  ];

  if (!allProducts.data) {
    return null;
  }

  if (!allProducts.data.length) {
    return (
      <div className="h-full flex flex-col items-center justify-center">
        <div className="pb-9">
          <HandMetal size={64} strokeWidth={1} />
        </div>
        <div className="text-center flex flex-col pb-12">
          <div className="pb-3 text-2xl">{t("products_addFirstProduct_title", "Create your first product")}</div>
          <div className="text-muted-foreground w-96 text-sm">
            {t(
              "products_addFirstProduct_content",
              "Welcome to Pulso! Creating your first product is like delivering the opening joke at a party — if it lands, everyone’s in for a great time!"
            )}
          </div>
        </div>
        <div>
          <NewProductModal onProductAdded={(id) => navigate(`/facility/${facilityId}/products/${id}/details`)}>
            <Button>{t("products_addFirstProduct_button", "Create product")}</Button>
          </NewProductModal>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex h-full">
        <div
          className="w-72 pb-6 flex-shrink-0 bg-secondary-dark text-sm h-full overflow-y-auto"
          style={{
            scrollbarColor: "#bbb rgb(var(--secondary-dark) / var(--tw-bg-opacity))",
          }}
        >
          <div className="p-6 flex justify-between items-center">
            <div className="text-lg font-medium">{t("products_title")}</div>
            <div>
              <NewProductModal onProductAdded={(id) => navigate(`/facility/${facilityId}/products/${id}/details`)}>
                <div>
                  <TooltipSimple text={t("products_addProduct_button", "Create new product")}>
                    <Button variant="ghost" className="!pr-0 hover:bg-transparent">
                      <Plus size={16} />
                    </Button>
                  </TooltipSimple>
                </div>
              </NewProductModal>
            </div>
          </div>
          <div className="flex flex-col gap-0">
            {products.map((product, index) => (
              <ProductItem
                key={product.id}
                product={product}
                isSelected={productId === product.id}
                onMove={(dir) => onMove(dir, 0, product.id)}
                canMoveUp={index > 0}
                canMoveDown={index < products.length - 1}
                subPage={subPage}
              />
            ))}
          </div>
          {complements.length > 0 && (
            <>
              <div className="p-6 flex justify-between items-center">
                <div className="text-lg font-medium">{t("product_complements_title", "Complements")}</div>
                <div>
                  <NewProductModal
                    isComplement={true}
                    onProductAdded={(id) => navigate(`/facility/${facilityId}/products/${id}/details`)}
                  >
                    <div>
                      <TooltipSimple text={t("products_addProduct_complement_button", "Create new complement")}>
                        <Button variant="ghost" className="!pr-0 hover:bg-transparent">
                          <Plus size={16} />
                        </Button>
                      </TooltipSimple>
                    </div>
                  </NewProductModal>
                </div>
              </div>
              <div className="flex flex-col gap-0">
                {complements.map((product, index) => (
                  <ProductItem
                    key={product.id}
                    product={product}
                    isSelected={productId === product.id}
                    onMove={(dir) => onMove(dir, 1000, product.id)}
                    canMoveUp={index > 0}
                    canMoveDown={index < complements.length - 1}
                    subPage={subPage}
                  />
                ))}
              </div>
            </>
          )}
        </div>
        <div className="flex-1 p-6 overflow-y-auto flex flex-col min-w-[700px]" id="productsPageWrapper">
          {currentProduct && (
            <div className="pl-3 flex items-center gap-3">
              <img src={currentProduct.imageUrl} className="w-16 h-16 rounded object-contain bg-white p-1" />
              <PageHeader title={currentProduct.name} className="flex-1" subtitle="" hideSeparator>
                <ConfirmDialog
                  title={t("products_deleteModal_title", "Delete {{product}}?", { product: currentProduct.name })}
                  description={t("products_deleteModal_description", "Are you sure you want to delete {{product}}?", {
                    product: currentProduct.name,
                  })}
                  onContinue={() => {
                    deleteProduct.mutate(currentProduct.id, {
                      onSuccess: () => {
                        setStoredProductId(products.find((p) => p.id !== currentProduct.id)?.id ?? "");
                        navigate(`/facility/${facilityId}/products`);
                      },
                    });
                  }}
                  okButtonVariant="destructive"
                >
                  <Button variant="destructiveOutline" size="icon" onClick={(e) => e.stopPropagation()}>
                    <Trash2 size={22} strokeWidth={1.2} />
                  </Button>
                </ConfirmDialog>
              </PageHeader>
            </div>
          )}
          {currentProduct && (
            <div className="flex mb-6 pt-6 text-sm border-b border-secondary-dark">
              {submenuItems
                .filter((pr) => !pr.hideIf || !pr.hideIf(currentProduct))
                .map((item) => (
                  <Link
                    key={item.link}
                    to={`./${productId}/${item.link}`}
                    className={cn(
                      "px-4 py-2 flex items-center gap-1 cursor-pointer text-muted-foreground border-b-2 border-transparent hover:border-primary",
                      subPage === item.link && "border-primary text-primary font-bold"
                    )}
                  >
                    {item.label}
                  </Link>
                ))}
            </div>
          )}

          <Outlet />
        </div>
      </div>
    </>
  );

  function onMove(direction: "up" | "down", offset: number, productId: string) {
    const productsToOrdered = allProducts.data || [];
    const index = productsToOrdered.findIndex((p) => p.id === productId);
    const targetIndex = direction === "up" ? index - 1 : index + 1;

    // Swap them
    const temp = productsToOrdered[index];
    productsToOrdered[index] = productsToOrdered[targetIndex];
    productsToOrdered[targetIndex] = temp;

    const productsOrdered = sortBy(productsToOrdered, "isComplement");
    const positions = productsOrdered.map((p, position) => ({
      productId: p.id,
      position: position + 1,
    }));

    reorderProducts.mutate({ products: positions });
  }
});

function ProductItem({
  product,
  isSelected,
  onMove,
  canMoveUp,
  canMoveDown,
  subPage,
}: {
  product: { id: string; imageUrl: string; name: string };
  isSelected?: boolean;
  onMove: (direction: "up" | "down") => void;
  canMoveUp?: boolean;
  canMoveDown?: boolean;
  subPage: string;
}) {
  const { t } = useTranslation();

  return (
    <Link to={`./${product.id}/${subPage}`} key={product.id} className="pt-1 px-3 block cursor-pointer group">
      <div
        className={cn(
          "flex py-2 px-3 items-center gap-3 hover:bg-secondary-darker rounded-md",
          isSelected && "bg-secondary-darker font-bold"
        )}
      >
        <img src={product.imageUrl} className="w-4 max-h-4 rounded" />
        <div className="truncate">{product.name}</div>
        <div className="ml-auto hidden group-hover:flex items-center">
          <TooltipSimple
            text={t("common_button_moveUp", "Move up")}
            className={cn("flex items-center", !canMoveUp && "invisible")}
            side="left"
          >
            <Button className="h-5 w-5" size="icon" variant="ghost" onClick={() => onMove("up")}>
              <ArrowUp size={18} strokeWidth={1.2} />
            </Button>
          </TooltipSimple>

          <TooltipSimple
            text={t("common_button_moveDown", "Move down")}
            className={cn("flex items-center", !canMoveDown && "invisible")}
            side="right"
          >
            <Button className="h-5 w-5" size="icon" variant="ghost" onClick={() => onMove("down")}>
              <ArrowDown size={18} strokeWidth={1.2} />
            </Button>
          </TooltipSimple>
        </div>
      </div>
    </Link>
  );
}
