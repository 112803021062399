import { useBookingCreate } from "@/api/useBookingCreate";
import { useCustomers } from "@/api/useCustomers";
import { PulsoForm, PulsoFormProvider } from "@/components/specific/Form";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Spinner } from "@/components/ui/spinner";
import { UserCard } from "@/components/ui/user-card";
import { cn } from "@/lib/utils";
import { ApiObjects } from "@pulso/api-client";
import { t } from "i18next";
import { PropsWithChildren, memo, useCallback, useDeferredValue, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { toast } from "sonner";
import { formatDateTnLocalTimezone, formatPrice, setTime } from "@pulso/utils";
import { DateFormat } from "@/components/specific/DateFormat";
import { useRentalPeriods } from "@/api/useRentalPeriods";
import { useProductsVariants } from "@/api/useProductsVariants";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import { Link, useNavigate } from "react-router-dom";
import { useCustomerForm } from "./details/useCustomerForm";
import { Edit2, X } from "lucide-react";
import { CustomTimePicker } from "@/components/ui/custom-time-picker";
import { useFacilitySettings } from "@/api/useFacilitySettings";
import { Button } from "@/components/ui/button";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useFacilityFastTrackSettings } from "@/api/useFastTrackSettings";
import { Warning } from "@/components/specific/Warning";
import { useAuth } from "@/lib/useAuth";

const CustomerFormMemo = memo(CustomerForm);

export type NewBookingModalProps = PropsWithChildren<{
  initialData?: {
    firstname: string;
    lastname: string;
    fields?: Record<string, string>;
    date?: Date;
    products?: ApiObjects["CreateBookingBody"]["products"];
  } | null;
  language: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  facilityId: string;
  contentClassName?: string;
  noRedirect?: boolean;
}>;

export function NewBookingModal({
  initialData,
  language,
  open,
  onOpenChange,
  contentClassName,
  facilityId,
  noRedirect,
  children,
}: NewBookingModalProps) {
  const { facility } = useAuth();
  const [customerFormType, setCustomerFormType] = useState<"quick" | "full">("full");
  const [names, setNames] = useState({ firstname: "", lastname: "" });
  const [fields, setFields] = useState<Record<string, string>>({});
  const [searchRaw, setSearch] = useState("");
  const search = useDeferredValue(searchRaw);
  const { customers, isLoading: existingCustomersLoading, total: totalExistingCustomers } = useCustomers(search, 6);
  const [existingUsersOpen, setExistingUsersOpen] = useState(false);
  const navigate = useNavigate();
  const { periods } = useRentalPeriods();
  const { variants } = useProductsVariants(facilityId);
  const variantsMap = keyBy(variants, (v) => v.variantId);
  const periodsMap = keyBy(periods, (p) => p.id);
  const [startAt, setStartAt] = useState(initialData?.date ? formatDateTnLocalTimezone(initialData.date) : null);
  const startAtDate = startAt ? new Date(startAt) : null;
  const startTime = startAtDate ? startAtDate.getHours() * 60 + startAtDate.getMinutes() : 0;
  const { settings } = useFacilitySettings(facilityId);
  const productsToAdd = Object.entries(groupBy(initialData?.products || [], (p) => p.variantId + "_" + p.periodId))
    .filter(([variantPeriodKey]) => variantsMap[variantPeriodKey.split("_")[0]])
    .map(([variantPeriodKey, items]) => {
      const [variantId, periodId] = variantPeriodKey.split("_");
      return {
        label: variantsMap[variantId]?.name,
        periodLabel: periodsMap[periodId]?.name,
        key: variantId,
        count: items.length,
        price: variantsMap[variantId].prices.find((price) => price.periodId === periodId)?.price || 0,
      };
    });
  const brokenProduct = Object.entries(
    groupBy(initialData?.products || [], (p) => p.variantId + "_" + p.periodId)
  ).find(([variantPeriodKey]) => !variantsMap[variantPeriodKey.split("_")[0]]);
  const hasProductsOrDate = Boolean(startAt || productsToAdd.length);
  const { settings: fastTrackSettings } = useFacilityFastTrackSettings(facilityId);

  useEffect(() => {
    if (initialData) {
      setNames({
        firstname: initialData.firstname,
        lastname: initialData.lastname,
      });
      setSearch(`${initialData.firstname} ${initialData.lastname}`.trim());
      if (initialData.fields) {
        setFields(initialData.fields);
      }
      setStartAt(initialData?.date ? formatDateTnLocalTimezone(initialData.date) : null);
    }
  }, [initialData]);

  useEffect(() => {
    if (!open) {
      setSearch("");
      setNames({ firstname: "", lastname: "" });
      setFields({});
      setExistingUsersOpen(false);
    }
  }, [open]);

  useEffect(() => {
    setExistingUsersOpen(search.length > 2);
  }, [search]);

  const create = useBookingCreate();
  const onSubmit = useCallback(
    (data: ApiObjects["CreateBookingBody"]) =>
      create.mutate(
        {
          customerId: data.customerId,
          fields: data.fields,
          firstname: data.firstname,
          lastname: data.lastname,
          email: data.email || undefined,
          language: data.language,
          sendFastTrackLink: data.sendFastTrackLink,
          ...(startAt ? { date: startAt } : {}),
          ...(initialData?.products
            ? {
                products: initialData.products.map((p) => ({
                  periodId: p.periodId,
                  variantId: p.variantId,
                  productId: p.productId,
                  stockItemId: p.stockItemId,
                })),
              }
            : {}),
        },
        {
          onSuccess,
          onError: (error: unknown) => {
            if (
              typeof error !== "object" ||
              !error ||
              !("body" in error) ||
              typeof error.body !== "object" ||
              !error.body ||
              !("error" in error.body) ||
              error.body.error !== "ConflictingStateError"
            )
              toast.error(t("bookings_add_error", "Booking creation error"));
          },
        }
      ),
    [initialData, create]
  );

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent
        onClick={() => customers.length === 0 && setSearch("")}
        hideFooter
        size="xl"
        className={cn("lg:w-[500px] p-0", contentClassName)}
      >
        {/* <div className="absolute right-0">
          <Button onClick={() => setIsQuickBooking(!isQuickBooking)}>Change</Button>
        </div> */}
        <DialogHeader className="p-6 pb-0">
          <DialogTitle>
            {customerFormType === "quick"
              ? t("bookings_requestBooking_title", "Quick booking")
              : t("bookings_add_customer_title", "Select customer")}
          </DialogTitle>
          <DialogDescription>
            {customerFormType === "quick"
              ? t(
                  "bookings_requestBooking_subtitle",
                  "Enter only the basic customer information. Complete the rest later or send a Fast Track link, so that the customer can complete it."
                )
              : t("bookings_add_customer_subtitle", "Choose the customer who is booking")}
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-4 overflow-hidden">
          <Tabs
            value={customerFormType}
            onValueChange={(value) => setCustomerFormType(value as "full" | "quick")}
            className="w-full"
          >
            <TabsList className="w-full text-sm">
              <TabsTrigger value="full" className="flex-1">
                {t("bookings_add_tabs_fullBooking", "Full booking")}
              </TabsTrigger>
              <TabsTrigger value="quick" className="flex-1">
                {t("bookings_add_tabs_quickBooking", "Quick booking")}
              </TabsTrigger>
            </TabsList>
          </Tabs>
          {hasProductsOrDate && (
            <div className="flex flex-col gap-3 p-3 px-6 bg-secondary-lighter border text-sm">
              {startAt && settings && (
                <div className="space-y-0 grid items-center grid-cols-form-row">
                  <div className="font-medium">{t("bookings_add_date_title", "Start date")}:</div>
                  <CustomTimePicker
                    value={startTime}
                    onChange={(time) => setStartAt(formatDateTnLocalTimezone(setTime(startAt, time)))}
                    granularity={settings.timeGranularity}
                  >
                    <div className="flex gap-1 items-center">
                      <DateFormat date={startAt} />
                      <Edit2 size={14} className="cursor-pointer" />
                    </div>
                  </CustomTimePicker>
                </div>
              )}
              {productsToAdd.length > 0 && (
                <div className="space-y-0 grid items-center grid-cols-form-row">
                  <div className="font-medium">{t("bookings_add_duration_title", "Duration")}:</div>
                  {productsToAdd[0].periodLabel}
                </div>
              )}
              {brokenProduct?.[1]?.[0]?.productId ? (
                <div className="space-y-0 grid items-center grid-cols-form-row">
                  <div className="font-medium">{t("bookings_add_products_title", "Products")}:</div>
                  <div className="text-destructive italic underline">
                    <Link
                      to={`/facility/${facilityId}/products/${brokenProduct?.[1]?.[0]?.productId}/inventory`}
                      className="flex items-center gap-1"
                    >
                      <Warning /> {t("bookings_add_products_error", "Invalid products")}
                    </Link>
                  </div>
                </div>
              ) : productsToAdd.length > 0 ? (
                <div className="space-y-0 grid items-center grid-cols-form-row">
                  <div className="font-medium">{t("bookings_add_products_title", "Products")}:</div>
                  <div>
                    {productsToAdd.map((product) => (
                      <div key={product.key}>
                        {product.count} x {product.label} ({formatPrice(product.price, facility?.currency ?? "EUR")})
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          )}
          <div className="flex flex-col gap-3 overflow-y-hidden pt-0 flex-1">
            <CustomerFormMemo
              names={names}
              fields={fields}
              language={language}
              onSubmit={onSubmit}
              isLoading={create.isPending}
              onNameChange={(name) => setSearch(name)}
              formType={customerFormType}
              fastTrackEnabled={fastTrackSettings?.enabled}
              productIds={initialData?.products?.map((p) => p.productId)}
            />
          </div>
        </div>
        {existingUsersOpen && (
          <div className="absolute left-6 right-6 bottom-20 flex justify-center lg:pt-0 lg:right-full lg:top-0 lg:bottom-auto lg:left-auto">
            <div className="bg-white py-3 px-6 lg:p-3 rounded-lg shadow w-full lg:w-64 lg:mr-1">
              {existingCustomersLoading && (
                <div className="absolute top-5 right-12 lg:right-12">
                  <Spinner size={14} />
                </div>
              )}
              <div className="mb-1 lg:mb-3 lg:px-3 text-muted-foreground flex justify-between items-center">
                <span> {t("bookings_create_customersSuggestions_title", "Returning customers")}</span>
                <Button variant="ghost" size="sm" onClick={() => setSearch("")} className="-mr-3">
                  <X size={16} />
                </Button>
              </div>
              {customers.length === 0 ? (
                <div className="p-3 text-sm text-muted-foreground" onClick={onCreateNew}>
                  {t("bookings_create_customersSuggestions_notFound", 'No customer found searching for "{{search}}".', {
                    search,
                  })}
                </div>
              ) : (
                <div className="grid grid-cols-3 lg:grid-cols-1 gap-3 lg:gap-0">
                  {customers.map((customer) => (
                    <UserCard
                      key={customer.id}
                      className="cursor-pointer bg-secondary-light hover:bg-secondary-lighter lg:bg-white lg:hover:bg-secondary-light px-3 py-2 rounded-md"
                      user={customer}
                      onClick={() => onSelect(customer.id)}
                      noAvatar
                    />
                  ))}
                  {totalExistingCustomers > customers.length && (
                    <div className="text-muted-foreground pt-1 lg:px-3 lg:pt-3 text-sm">
                      {t("bookings_create_customersSuggestions_more", "+ {{count}} more", {
                        count: totalExistingCustomers - customers.length,
                      })}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );

  function onSelect(selectedCustomer: string) {
    const customer = customers.find((c) => c.id === selectedCustomer);
    if (customer) {
      create.mutate(
        {
          customerId: customer.id,
          firstname: customer.firstname.trim(),
          lastname: customer.lastname.trim(),
          language: customer.language,
          fields: [],
          ...(startAt ? { date: startAt } : {}),
          ...(initialData?.products
            ? {
                products: initialData.products.map((p) => ({
                  periodId: p.periodId,
                  variantId: p.variantId,
                  productId: p.productId,
                  stockItemId: p.stockItemId,
                })),
              }
            : {}),
        },
        {
          onSuccess,
        }
      );
    }
  }

  function onCreateNew() {
    const [firstname, ...lastnameParts] = search.split(" ");
    setNames({ firstname, lastname: lastnameParts.join(" ") });
  }

  function onSuccess(booking: ApiObjects["BookingDto"]) {
    onOpenChange(false);
    if (!noRedirect) {
      navigate(`/facility/${facilityId}/bookings/${booking.id}`);
    }
  }
}

function CustomerForm({
  onSubmit,
  onNameChange,
  names,
  language,
  fields,
  isLoading,
  isSecondary,
  formType,
  fastTrackEnabled,
  productIds,
}: {
  onSubmit: (data: ApiObjects["CreateBookingBody"]) => void;
  onNameChange: (name: string) => void;
  names: { firstname: string; lastname: string };
  language: string;
  fields?: Record<string, string>;
  isLoading: boolean;
  isSecondary?: boolean;
  formType: "full" | "quick";
  fastTrackEnabled?: boolean;
  productIds?: string[];
}) {
  const formDef = useCustomerForm(formType, fastTrackEnabled, productIds);

  return (
    <PulsoFormProvider
      {...formDef}
      onSubmit={onSubmit}
      initialValues={{
        firstname: names.firstname || "",
        lastname: names.lastname || "",
        email: "",
        language,
        sendFastTrackLink: false,
        ...(fields || {}),
      }}
      dontAutoRenderForm
      className="flex flex-col flex-1 px-6 pb-6 overflow-y-hidden"
      fieldsClassName="flex-1 overflow-y-auto"
      isLoading={isLoading}
      onChange={(values, info) => {
        if (info.name === "firstname" || info.name === "lastname") {
          onNameChange((values.firstname + " " + values.lastname).trim());
        }
      }}
    >
      <CustomerFormInner names={names} fields={fields} isSecondary={isSecondary} />
    </PulsoFormProvider>
  );
}

function CustomerFormInner(props: {
  names: { firstname: string; lastname: string };
  fields?: Record<string, string>;
  isSecondary?: boolean;
}) {
  const form = useFormContext();
  useEffect(() => {
    form.setValue("firstname", props.names.firstname);
    form.setValue("lastname", props.names.lastname);
  }, [props.names]);

  useEffect(() => {
    if (props.fields) {
      Object.entries(props.fields).forEach(([key, value]) => {
        form.setValue(key, value);
      });
    }
  }, [props.fields]);
  return <PulsoForm horizontal />;
}
