import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCustomerFieldsForm } from "./useCustomerFieldsForm";
import { ApiObjects } from "@pulso/api-client";
import { PulsoFormProvider } from "@/components/specific/Form";

type CustomerFieldUpdateDialogProps = PropsWithChildren<{
  field: ApiObjects["CustomerFieldDto"];
  onUpdate: (field: { id: string } & ApiObjects["CustomerFieldUpdateBody"], onSuccess: () => void) => void;
  isUpdating: boolean;
  forBooking?: boolean;
}>;

export function CustomerFieldUpdateDialog({
  field,
  onUpdate,
  isUpdating,
  forBooking,
  children,
}: CustomerFieldUpdateDialogProps) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const form = useCustomerFieldsForm({
    allowEditingType: false,
    hideOptions: field.type !== "OPTION",
    type: field.type,
    forBooking: forBooking || false,
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent hideFooter>
        <DialogHeader>
          <DialogTitle>{t("customerFields_update_title", "Edit {{field}}", { field: field.name })}</DialogTitle>
          <DialogDescription>{t("customerFields_update_subtitle", "Modify the field's settings")}</DialogDescription>
        </DialogHeader>
        <div className="overflow-y-auto">
          <PulsoFormProvider
            schema={form.schema}
            fields={form.fields}
            onSubmit={(values) => onUpdate({ id: field.id, ...values }, () => setOpen(false))}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            initialValues={{ ...field, options: field.options.map((value, id) => ({ value, id })) as any }}
            isLoading={isUpdating}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}
