import { PulsoFormProvider, createForm } from "@/components/specific/Form";
import { ApiObjects } from "@pulso/api-client";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { useUserRoleLabel } from "./useUserRoleLabel";

export type FormData = {
  firstname: string;
  lastname: string;
  email: string;
  language: string;
  role: ApiObjects["CreateUserBody"]["role"];
};

export type UserFormProps = {
  users: ApiObjects["UserDto"][];
  initialData: FormData;
  onSubmit: (data: FormData) => void;
};

export function UserForm(props: UserFormProps) {
  const { t } = useTranslation();
  const formSchema = z.object({
    firstname: z.string().min(1).max(50),
    lastname: z.string().min(1).max(50),
    email: z.string().min(1).max(50).email(),
    language: z.string(),
    role: z.enum(["ADMIN", "USER", "MANAGER"]),
  });
  const roleLabels = useUserRoleLabel();

  const form = createForm(
    {
      firstname: {
        label: t("settings_users_form_label_firstname", "First name"),
        type: "string" as const,
      },
      lastname: {
        label: t("settings_users_form_label_lasttname", "Last name"),
        type: "string" as const,
      },
      email: {
        label: t("settings_users_form_label_email", "Email"),
        type: "string" as const,
      },
      role: {
        label: t("settings_users_form_role_label", "Role"),
        type: "select",
        options: [
          { label: roleLabels["ADMIN"], value: "ADMIN" },
          { label: roleLabels["MANAGER"], value: "MANAGER" },
          { label: roleLabels["USER"], value: "USER" },
        ],
      },
      language: {
        label: t("settings_users_form_label_langauge", "Language"),
        type: "language",
        languages: ["es", "en"],
      },
    },
    formSchema
  );

  return <PulsoFormProvider {...form} onSubmit={props.onSubmit} initialValues={props.initialData} />;
}
