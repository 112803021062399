"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CURRENCY_SIGN = exports.CURRENCY = void 0;
exports.formatPrice = formatPrice;
exports.CURRENCY = {
    EUR: { sign: "€", label: "Euro" },
    USD: { sign: "$", label: "United States Dollar" },
    GBP: { sign: "£", label: "British Pound Sterling" },
    AUD: { sign: "$", label: "Australian Dollar" },
    CAD: { sign: "$", label: "Canadian Dollar" },
    MXN: { sign: "$", label: "Mexican Peso" },
    BRL: { sign: "R$", label: "Brazilian Real " },
    ARS: { sign: "$", label: "Argentine Peso" },
    CLP: { sign: "$", label: "Chilean Peso" },
    COP: { sign: "$", label: "Colombian Peso" },
    CRC: { sign: "₡", label: "Costa Rican Colón" },
    DKK: { sign: "kr.", label: "Danish Krone", isTrailing: true },
    CZK: { sign: "Kč", label: "Czech Koruna", isTrailing: true },
    HUF: { sign: "Ft", label: "Hungarian Forint", isTrailing: true },
    VES: { sign: "Bs.", label: "Venezuelan Bolívar" },
    AED: { sign: "AED", label: "United Arab Emirates Dirham" },
};
exports.CURRENCY_SIGN = Object.entries(exports.CURRENCY).reduce((acc, [key, value]) => (Object.assign(Object.assign({}, acc), { [key]: value.sign })), {});
const priceFormatter = new Intl.NumberFormat(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
const noCentsPriceFormatter = new Intl.NumberFormat(undefined, { maximumFractionDigits: 0 });
function formatPrice(price, currency, skipCents = false) {
    var _a;
    const currencyTransformed = currency.toUpperCase();
    const sign = exports.CURRENCY_SIGN[currencyTransformed] || currencyTransformed;
    const isTrailing = !!((_a = exports.CURRENCY[currencyTransformed]) === null || _a === void 0 ? void 0 : _a.isTrailing);
    const formatter = skipCents ? noCentsPriceFormatter : priceFormatter;
    const priceFormatted = formatter.format(price);
    return isTrailing ? `${priceFormatted} ${sign}` : `${sign}${priceFormatted}`;
}
