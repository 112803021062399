import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCustomerFieldsForm } from "./useCustomerFieldsForm";
import { PulsoFormProvider } from "@/components/specific/Form";
import { ApiObjects } from "@pulso/api-client";

type CustomerFieldCreateDialogProps = {
  onCreate: (data: ApiObjects["CustomerFieldCreateBody"], onSuccess: () => void) => void;
  isCreating: boolean;
  forBooking?: boolean;
};

export function CustomerFieldCreateDialog(props: CustomerFieldCreateDialogProps) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const form = useCustomerFieldsForm({ forBooking: props.forBooking || false });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">{t("customerFields_create_openButton", "Add customer field")}</Button>
      </DialogTrigger>
      <DialogContent hideFooter>
        <DialogHeader>
          <DialogTitle>{t("customerFields_create_title", "Add a new customer field")}</DialogTitle>
          <DialogDescription>
            {t("customerFields_create_subtitle", "Define a field which describes a customer")}
          </DialogDescription>
        </DialogHeader>
        <div className="overflow-y-auto">
          <PulsoFormProvider
            {...form}
            onSubmit={(values) => props.onCreate(values, () => setOpen(false))}
            isLoading={props.isCreating}
            initialValues={{
              name: "",
              type: "TEXT",
              options: [],
              mandatory: false,
              showInFastTrack: true,
              showInStore: false,
              forProductIds: [],
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}
