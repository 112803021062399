import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { PropsWithChildren, useEffect, useState } from "react";
import { useProductCreate } from "@/api/useProductCreate";
import { t } from "i18next";
import { createProductForm } from "./createProductForm";
import { PulsoFormProvider } from "@/components/specific/Form";

export function NewProductModal(
  props: PropsWithChildren<{
    isOpen?: boolean;
    onOpenChange?: (isOpen: boolean) => void;
    onProductAdded?: (productId: string) => void;
    isComplement?: boolean;
  }>
) {
  const [open, setOpen] = useState(props.isOpen);

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  const form = createProductForm();

  const createProduct = useProductCreate();
  return (
    <Dialog
      open={open}
      onOpenChange={(o) => {
        setOpen(o);
        props.onOpenChange?.(o);
      }}
    >
      <DialogTrigger asChild>{props.children}</DialogTrigger>
      <DialogContent className="lg:max-w-[425px]" hideFooter>
        <DialogHeader>
          <DialogTitle>{t("products_create_title", "New product")}</DialogTitle>
          <DialogDescription>
            {t("products_create_subtitle", "Define the a product that you are offering")}
          </DialogDescription>
        </DialogHeader>
        <PulsoFormProvider
          {...form}
          isLoading={createProduct.isPending}
          onSubmit={(values) =>
            createProduct.mutate(
              {
                name: values.name,
                description: values.description,
                identifiable: values.productType === "unitary",
                isPackage: values.productType === "package",
                isComplement: props.isComplement ?? false,
              },
              {
                onSuccess: (newProduct) => {
                  close();
                  props.onProductAdded?.(newProduct.id);
                },
              }
            )
          }
          initialValues={{ name: "", description: "", productType: "unitary" }}
        />
      </DialogContent>
    </Dialog>
  );

  function close() {
    setOpen(false);
    props.onOpenChange?.(false);
  }
}
