"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.documentCss = void 0;
exports.minutesToLabel = minutesToLabel;
exports.pad = pad;
exports.notEmpty = notEmpty;
var documentCss_1 = require("./documentCss");
Object.defineProperty(exports, "documentCss", { enumerable: true, get: function () { return documentCss_1.documentCss; } });
__exportStar(require("./bookings"), exports);
__exportStar(require("./variants"), exports);
__exportStar(require("./dates"), exports);
__exportStar(require("./errors"), exports);
__exportStar(require("./handlebarsHelpers"), exports);
__exportStar(require("./seasons"), exports);
__exportStar(require("./mail"), exports);
__exportStar(require("./exports"), exports);
__exportStar(require("./languages"), exports);
__exportStar(require("./crypto"), exports);
__exportStar(require("./payments"), exports);
__exportStar(require("./price"), exports);
function minutesToLabel(minutes) {
    const m = typeof minutes === "string" ? parseInt(minutes, 10) : minutes;
    return `${pad(m / 60)}:${pad(m % 60)}`;
}
function pad(i, length = 2) {
    const str = Math.floor(i) + "";
    return "00000000000000000000000".slice(0, length - str.length) + str;
}
function notEmpty(value) {
    return value !== null && value !== undefined;
}
// type PEx<T extends object, Prop extends keyof T> =
//   T extends Record<Prop, infer Q> ? Omit<T, Prop> & Record<Prop, Required<Q>> : never;
// export function notEmptyPropPredicate<TProp extends string, Q, T extends Record<TProp, Q>>(prop: TProp) {
//   return function notEmpty(value: T): value is PEx<T, Q> {
//     return value[prop] !== null && value[prop] !== undefined;
//   };
// }
