import { Button } from "@/components/ui/button";
import { CentralSpinner } from "@/components/ui/central-spinner";
import { ConfirmDialog } from "@/components/ui/confirm-dialog";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";
import { ApiObjects } from "@pulso/api-client";
import { ButtonCallback } from "@pulso/components/lib/ButtonCallback";
import { Spinner } from "@pulso/components/lib/Spinner";
import { StripeConnectInstance } from "@stripe/connect-js";
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
  ConnectNotificationBanner,
} from "@stripe/react-connect-js";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AlertTriangle, Check } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useStripeConnect from "./useStripeConnect";

export function StripeSettings(props: { facilityId: string }) {
  const { data: settings, isLoading } = useQuery({
    queryKey: ["stripe-settings", props.facilityId],
    queryFn: () => api.getStripeConnectedAccount({ facilityId: props.facilityId }),
  });
  const stripeConnectInstance = useStripeConnect(settings?.accountId, props.facilityId);

  if (isLoading || !settings) {
    return <Spinner />;
  }

  return (
    <StripeSettingsInternal
      facilityId={props.facilityId}
      settings={settings}
      stripeConnectInstance={stripeConnectInstance}
    />
  );
}

export function StripeSettingsInternal(props: {
  facilityId: string;
  settings: ApiObjects["StripeAccount"];
  stripeConnectInstance?: StripeConnectInstance;
}) {
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  return (
    <div>
      {!props.settings.accountId ? (
        <StripeNoAccountCreated facilityId={props.facilityId} onAccountCreated={() => setSettingsModalOpen(true)} />
      ) : (
        <StripeConnected
          facilityId={props.facilityId}
          stripeConnectInstance={props.stripeConnectInstance}
          isIncomplete={!props.settings.detailsSubmitted}
          onOpenModal={() => setSettingsModalOpen(true)}
        />
      )}
      <div>
        {props.stripeConnectInstance && settingsModalOpen && (
          <StripeInfoModal
            stripeConnectInstance={props.stripeConnectInstance}
            onClose={() => setSettingsModalOpen(false)}
          />
        )}
      </div>
    </div>
  );
}

export function StripeNoAccountCreated(props: { facilityId: string; onAccountCreated: () => void }) {
  const { t } = useTranslation();
  const createAccountMutation = useMutation({
    mutationFn: () => api.createStripeConnectedAccount({ facilityId: props.facilityId }, undefined),
    invalidateKeys: [["stripe-settings"]],
    onSuccess: () => props.onAccountCreated(),
  });
  return (
    <div className="p-6 rounded border flex items-center">
      <div className="mr-3">
        <img src="/img/payment-providers/stripe.svg" className="h-8" />
      </div>
      <div>{t("settings_payments_stripe_notConnected_info", "Connect your Stripe account")}</div>
      <div className="ml-auto">
        <ButtonCallback variant="outline" onClick={() => createAccountMutation.mutateAsync()}>
          {t("settings_payments_stripe_notConnected_connect_button", "Connect")}
        </ButtonCallback>
      </div>
    </div>
  );
}

export function StripeConnected(props: {
  facilityId: string;
  stripeConnectInstance?: StripeConnectInstance;
  isIncomplete: boolean;
  onOpenModal: () => void;
}) {
  const { t } = useTranslation();
  const disconnect = useMutation({
    mutationFn: () => api.disconnectStripe({ facilityId: props.facilityId }),
    invalidateKeys: [["stripe-settings"]],
    successMessage: "DELETE_SUCCESS",
  });
  const isDisconnectEnabled = false;

  return (
    <div>
      <div className="p-6 rounded border flex flex-wrap items-center">
        <div className="mr-3">
          <img src="/img/payment-providers/stripe.svg" className="h-8" />
        </div>
        {props.isIncomplete ? (
          <div className="flex items-center gap-3 text-sm">
            <AlertTriangle size={36} className="text-warn" />
            {t(
              "settings_payments_stripe_incomplete_info",
              "Stripe needs some data. Complete your account to start receiving payments."
            )}
          </div>
        ) : (
          <div className="flex items-center gap-3 text-sm">
            <Check size={36} className="text-green-700" />
            {t("settings_payments_stripe_connected_info", "Stripe is connected")}
          </div>
        )}

        <div className="ml-auto flex gap-3">
          <Button variant={props.isIncomplete ? "warn" : "outline"} onClick={() => props.onOpenModal()}>
            {props.isIncomplete
              ? t("settings_payments_stripe_incomplete_openSettings_button", "Complete account")
              : t("settings_payments_stripe_connected_openSettings_button", "Open settings")}
          </Button>
          {!props.isIncomplete && (
            <>
              <a href="https://dashboard.stripe.com" target="_blank">
                <Button variant="outline" style={{ borderColor: "rgb(83, 58, 253)", color: "rgb(83, 58, 253)" }}>
                  {t("settings_payments_stripe_connected_openStripe_button", "Open Stripe")}
                </Button>
              </a>
              <a href="https://dashboard.stripe.com/settings/payment_methods" target="_blank">
                <Button variant="outline" style={{ borderColor: "rgb(83, 58, 253)", color: "rgb(83, 58, 253)" }}>
                  {t("settings_payments_stripe_connected_openStripePaymentMethods_button", "Payment Methods")}
                </Button>
              </a>
            </>
          )}
          {isDisconnectEnabled && (
            <ConfirmDialog
              title={t("settings_payments_stripe_connected_disconnectModal_title", "Disconnect Stripe")}
              description={t(
                "settings_payments_stripe_connected_disconnectModal_description",
                "Are you sure you want to disconnect Stripe?"
              )}
              onContinue={() => disconnect.mutate()}
            >
              <Button variant="destructive">
                {t("settings_payments_stripe_connected_disconnect_button", "Disconnect")}
              </Button>
            </ConfirmDialog>
          )}
        </div>
      </div>
      {props.stripeConnectInstance ? (
        <ConnectComponentsProvider connectInstance={props.stripeConnectInstance}>
          <div className="mt-3">
            <ConnectNotificationBanner />
          </div>
        </ConnectComponentsProvider>
      ) : (
        <div className="mt-3">
          <CentralSpinner />
        </div>
      )}
    </div>
  );
}

export function StripeInfoModal(props: { stripeConnectInstance: StripeConnectInstance; onClose: () => void }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return (
    <Dialog
      open={true}
      onOpenChange={() => {
        invalidate();
        props.onClose();
      }}
    >
      <DialogContent size="lg" className="lg:h-4/5 overflow-y-auto">
        <DialogHeader>
          <DialogTitle>{t("settings_payments_stripe_connected_settingsModal_title", "Stripe settings")}</DialogTitle>
          <DialogDescription>
            {t("settings_payments_stripe_connected_settingsModal_description", "Review your stripe settings")}
          </DialogDescription>
        </DialogHeader>
        <div>
          <ConnectComponentsProvider connectInstance={props.stripeConnectInstance}>
            <ConnectAccountOnboarding onExit={invalidate} />
          </ConnectComponentsProvider>
        </div>
      </DialogContent>
    </Dialog>
  );

  function invalidate() {
    queryClient.invalidateQueries({ queryKey: ["stripe-settings"], refetchType: "all" });
  }
}
