import { useStoreSettings } from "@/api/useStoreSettings";
import { useStoreSettingsPatch } from "@/api/useStoreSettingsPatch";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { CentralSpinner } from "@/components/ui/central-spinner";
import { Form, FormField } from "@/components/ui/form";
import { Switch } from "@/components/ui/switch";
import { withFacilityIdParam } from "@/lib/withParams";
import { ApiObjects } from "@pulso/api-client";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Code } from "@pulso/components/lib/Code";
import { StripeSettings } from "./StripeSettings";
import { StoreSettingsForm } from "./StoreSettingsForm";
import { useProductsWithVariants } from "@/api/useProductsWithVariants";
import { Fragment, useEffect, useState } from "react";
import { Checkbox } from "@/components/ui/checkbox";
import { cn } from "@pulso/components/lib/utils";
import { RedsysSettings } from "./RedsysSettings";

const PAYMENT_PROVIDERS = [
  { key: "STRIPE", logo: "/img/payment-providers/stripe.svg" },
  { key: "REDSYS", logo: "/img/payment-providers/redsys.png" },
] as const;

export const StoreSettingsPage = withFacilityIdParam((params) => {
  const { t } = useTranslation();
  const { settings, isLoading } = useStoreSettings(params.facilityId);
  const patchSettings = useStoreSettingsPatch(params.facilityId);
  const { products } = useProductsWithVariants(params.facilityId);
  const variants =
    products?.flatMap((p) =>
      p.variants.map((v) => ({
        id: v.id,
        image: v.photo.imageUrl,
        name: v.fullName,
        productId: p.id,
      }))
    ) || [];

  const integrationScripts = {
    script: `<script src="https://app.pulsorent.com/store/store-loader.js" data-facility-id="${params.facilityId}"></script>`,
    button: `<button onclick="pulso.open('${params.facilityId}')">Book now</button>`,
    buttonHandler: `pulso.open('${params.facilityId}')`,
    addToBasketButton: `<button onclick="pulso.addToBasket('[PRODUCT_ID]', '[VARIANT_ID]', [REPLACE_BASKET], event)">Add to basket</button>`,
    addToBasketHandler: `pulso.addToBasket('[PRODUCT_ID]', '[VARIANT_ID]', [REPLACE_BASKET], event)`,
    link: `javascript:pulso.open('${params.facilityId}')`,
    basket: `<div id="pulsoBasket" data-facility-id="${params.facilityId}"></div>`,
  };

  if (!settings || isLoading) {
    return <CentralSpinner />;
  }

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>{t("settings_store_enable_title", "Online store")}</CardTitle>
          <CardDescription>
            {t(
              "settings_store_enable_description",
              "Enable Online Store to allow your customers to book directly from your website."
            )}
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="mb-1">
            <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
              {t("settings_store_enable_label", "Enable Online store")}
            </label>
          </div>
          <Switch checked={settings.enabled} onCheckedChange={(c) => patchSettings.mutate({ enabled: c })}></Switch>
        </CardContent>
      </Card>
      {settings.enabled && (
        <>
          <Card>
            <CardHeader>
              <CardTitle>{t("settings_store_link_title", "Try out store")}</CardTitle>
              <CardDescription>
                {t("settings_store_link_description", "Open a demo store that simulates the real customer experience.")}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <a
                target="_blank"
                href={
                  (process.env.REACT_APP_STORE_DEMO_URL || "") +
                  "?f=" +
                  params.facilityId +
                  "&apiUrl=" +
                  encodeURIComponent(process.env.REACT_APP_STORE_API_URL || "")
                }
              >
                <Button variant="outline">{t("settings_store_enable_title", "Open store")}</Button>
              </a>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>{t("settings_store_settings_title", "Store customization")}</CardTitle>
              <CardDescription>
                {t(
                  "settings_store_settings_description",
                  "Customzie your online store the way that best suites your business."
                )}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <StoreSettingsForm facilityId={params.facilityId} />
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>{t("settings_store_products_title", "Store Products")}</CardTitle>
              <CardDescription>
                {t(
                  "settings_store_products_description",
                  "Choose which products will be bookable in your online store."
                )}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <StoreProductForm facilityId={params.facilityId} settings={settings} />
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>{t("settings_store_payments_title", "Payments")}</CardTitle>
              <CardDescription>
                {t("settings_store_payments_description", "Connect a payment provider")}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Switch
                checked={settings.paymentsEnabled}
                onCheckedChange={(c) => patchSettings.mutate({ paymentsEnabled: c })}
              ></Switch>
              {settings.paymentsEnabled && (
                <>
                  <div className="">
                    <div>
                      <div className="flex items-center gap-3 my-6">
                        {PAYMENT_PROVIDERS.map((provider) => (
                          <div
                            key={provider.key}
                            className={cn(
                              "flex items-center gap-3 px-6 py-3 cursor-pointer border rounded-md",
                              settings.paymentProvider === provider.key && "border-2 border-gray-700"
                            )}
                            onClick={() => patchSettings.mutate({ paymentProvider: provider.key })}
                          >
                            <Checkbox checked={settings.paymentProvider === provider.key} />
                            <img src={provider.logo} className="h-8" />
                          </div>
                        ))}
                      </div>
                    </div>
                    {settings.paymentProvider === "STRIPE" ? (
                      <StripeSettings facilityId={params.facilityId} />
                    ) : settings.paymentProvider === "REDSYS" ? (
                      <RedsysSettings facilityId={params.facilityId} />
                    ) : null}
                  </div>
                </>
              )}
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>{t("settings_store_integrate_title", "Integrate in your webiste")}</CardTitle>
              <CardDescription>
                {t(
                  "settings_store_integrate_description",
                  "Follow the instructions to integrate the Pulso Online Store with your website."
                )}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <hr className="mb-6" />
              <div className="font-bold mb-1">{t("settings_store_integrate_button_title", "Single button wizard")}</div>
              <div className="mb-6 text-sm">
                {t(
                  "settings_store_integrate_button_subtitle",
                  "You can associate a button in your page to open the Pulso Online Store popup window where your clients can select a product and complete their booking."
                )}
              </div>

              <div className="text-sm space-y-6 pl-3">
                <div className="space-y-2">
                  <div>
                    1. {t("settings_store_integrate_addScript_title", "Add the loading script to your main page")}
                  </div>
                  <Code text={integrationScripts.script} copyButtonText={t("common_button_copy", "Copy")} />
                </div>
                <div className="space-y-2">
                  <div>2. {t("settings_store_integrate_addButton_title", 'Add or modify a "Book now" button')}</div>
                  <Code text={integrationScripts.button} copyButtonText={t("common_button_copy", "Copy")} />
                  <div>
                    {t(
                      "settings_store_integrate_addButtonHref_title",
                      "or use the following link for a button if you are using a CMS, such as WordPress: "
                    )}
                    <span className="font-mono bg-secondary/50 p-1 border rounded-md">{integrationScripts.link}</span>
                  </div>
                </div>
              </div>
              <hr className="my-6" />
              <div className="font-bold mb-1">
                {t("settings_store_integrate_popup_title", "Product specific button with a basket widget")}
              </div>
              <div className="mb-6 text-sm">
                {t(
                  "settings_store_integrate_popup_subtitle",
                  'This method adds a basket widget to your website. You can then assocaite multiple "Add to cart" buttons which would add products to the basket. Clients can complete their bookings by opening the basket.'
                )}
              </div>

              <div className="text-sm space-y-6 pl-3">
                <div className="space-y-2">
                  <div>
                    1. {t("settings_store_integrate_addScript_title", "Add the loading script to your main page")}
                  </div>
                  <Code text={integrationScripts.script} copyButtonText={t("common_button_copy", "Copy")} />
                </div>
                <div className="space-y-2">
                  <div>
                    2. {t("settings_store_integrate_addBasketElement_title", "Add the basket placeholder to your page")}
                  </div>
                  <Code text={integrationScripts.basket} copyButtonText={t("common_button_copy", "Copy")} />
                </div>
                <div className="space-y-2">
                  <div>
                    3.{" "}
                    {t(
                      "settings_store_integrate_addAddToBasketButton_title",
                      'Associate an "Add to basket" button with a product'
                    )}
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "settings_store_integrate_addAddToBasketButton_description",
                        "Pulso supports two integration modes for product buttons: <strong>Add to basket</strong> & <strong>Book now</strong>.<br />In <strong>Add to basket</strong> mode your clients can have multiple products in the basket and can add them one by one. For instance, they can add 1 adult bike and then 1 child bike and book them together in the same booking.<br />In <strong>Book now</strong> mode customers can only book 1 product at a time. If they click on the Book now button of another product, they will continue booking only the new product.<br />The integration codes are different for each mode. Please, choose the one that best fits your business and keep in mind that you can have multiple buttons on your page with different modes."
                      ),
                    }}
                  ></div>
                  <div className="pt-6 pb-3 text-lg font-bold">
                    {t("settings_store_integrate_buttonDesigner_title", "Button Designer")}
                  </div>
                  <div>
                    <ButtonDesigner
                      variants={variants.filter((v) => settings.variants.some((sv) => sv.variantId === v.id))}
                      integrationScripts={integrationScripts}
                    />
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </>
      )}
    </div>
  );
});

type StoreProductFormItem = { enabled: boolean; checkAvailability: boolean };
type StoreProductFormData = Record<string, StoreProductFormItem>;

function StoreProductForm(props: { facilityId: string; settings: ApiObjects["StoreSettingsDto"] }) {
  const { t } = useTranslation();
  const patchSettings = useStoreSettingsPatch(props.facilityId);
  const { products } = useProductsWithVariants(props.facilityId);

  const form = useForm<StoreProductFormData>({
    defaultValues:
      props.settings.variants.reduce(
        (obj, p) => ({
          ...obj,
          [p.variantId]: { enabled: true, checkAvailability: p.checkAvailability },
        }),
        {} as StoreProductFormData
      ) || {},
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSave)}>
        <table className="mb-6">
          <thead>
            <tr className="text-left">
              <th className="p-2 text-sm">{t("settings_store_products_name", "Product")}</th>
              <th className="p-2 text-sm">{t("settings_store_products_enabled", "Show in online store")}</th>
              <th className="p-2 text-sm">{t("settings_store_products_checkAvailability", "Check availability")}</th>
            </tr>
          </thead>
          <tbody>
            {products?.map((product) => (
              <Fragment key={product.id}>
                {product.variants.map((variant) => (
                  <tr key={variant.id} className="hover:bg-secondary rounded">
                    <td className="p-2 text-sm">{variant.fullName}</td>

                    <FormField
                      name={variant.id}
                      control={form.control}
                      render={({ field }) => (
                        <>
                          <td className="p-2 pr-6">
                            <div className="flex justify-center">
                              <Switch
                                checked={field.value?.enabled}
                                onCheckedChange={(enabled) => {
                                  field.onChange({ ...(field.value || {}), enabled, checkAvailability: enabled });
                                }}
                              />
                            </div>
                          </td>
                          <td className="p-2">
                            {field.value?.enabled && (
                              <div className="text-sm flex gap-1 items-center justify-center">
                                <Checkbox
                                  id={"checkAvailability" + variant.id}
                                  checked={field.value.checkAvailability}
                                  onCheckedChange={(checked) =>
                                    field.onChange({ ...(field.value || {}), checkAvailability: checked })
                                  }
                                />
                              </div>
                            )}
                          </td>
                        </>
                      )}
                    />
                  </tr>
                ))}
              </Fragment>
            ))}
          </tbody>
        </table>
        <Button type="submit">{t("common_button_save")}</Button>
      </form>
    </Form>
  );

  function onSave(formData: StoreProductFormData) {
    const variantsData = Object.keys(formData)
      .filter((variantId: string) => formData[variantId]?.enabled)
      .map((variantId) => ({
        variantId,
        checkAvailability: formData[variantId]?.checkAvailability,
      }));

    patchSettings.mutate({ variants: variantsData });
  }
}

function ButtonDesigner({
  variants,
  integrationScripts,
}: {
  integrationScripts: {
    addToBasketHandler: string;
    buttonHandler: string;
  };
  variants: { id: string; name: string; productId: string; image: string }[];
}) {
  const { t } = useTranslation();
  const [buttonText, setButtonText] = useState("Book now");
  const [bgColor, setBgColor] = useState("#007bff");
  const [textColor, setTextColor] = useState("#ffffff");
  const [borderColor, setBorderColor] = useState("#007bff");
  const [paddingVertical, setPaddingVertical] = useState(12);
  const [paddingHorizontal, setPaddingHorizontal] = useState(20);
  const [borderRadius, setBorderRadius] = useState(5);
  const [fontSize, setFontSize] = useState(16);
  const [variantId, setVariantId] = useState(variants[0]?.id);
  const [instructionsMode, setInstructionsMode] = useState<"add" | "replace">("add");
  const [showAllCodes, setShowAllCodes] = useState(false);
  const [buttonType, setButtonType] = useState<"button" | "basket">("basket");

  const variant = variants.find((v) => v.id === variantId);

  useEffect(() => {
    if (!variantId) {
      setVariantId(variants[0]?.id);
    }
  }, [variants]);

  const buttonStyle = {
    backgroundColor: bgColor,
    color: textColor,
    padding: `${paddingVertical}px ${paddingHorizontal}px`,
    borderRadius,
    fontSize,
    border: "1px solid " + borderColor,
    cursor: "pointer",
  };

  return (
    <div>
      <div className="grid grid-cols-[400px_400px] gap-6 items-center">
        <div className="flex flex-col gap-6">
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-4">
              <label className="block text-sm font-medium">
                {t("settings_store_integrate_buttonDesigner_buttonType", "Button type")}
              </label>
              <select
                onChange={(e) => setButtonType(e.target.value as "button")}
                className="border p-2 w-full"
                value={buttonType}
              >
                <option value="button">
                  {t("settings_store_integrate_buttonDesigner_buttonType_popup", "Wizard")}
                </option>
                <option value="basket">
                  {t("settings_store_integrate_buttonDesigner_buttonType_basket", "Basket")}
                </option>
              </select>
            </div>
            {buttonType === "basket" && (
              <div className="col-span-8">
                <label className="block text-sm font-medium">
                  {t("settings_store_integrate_buttonDesigner_select_variant", "Select Variant")}
                </label>
                <select onChange={(e) => setVariantId(e.target.value)} className="border p-2 w-full" value={variantId}>
                  {variants.map((variant) => (
                    <option key={variant.id} value={variant.id}>
                      {variant.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-4">
              <label className="block text-sm font-medium">
                {t("settings_store_integrate_buttonDesigner_button_text", "Button Text")}
              </label>
              <input
                type="text"
                value={buttonText}
                onChange={(e) => setButtonText(e.target.value)}
                className="border p-2 w-full"
              />
            </div>
            {buttonType === "basket" && (
              <div className="col-span-8">
                <label className="block text-sm font-medium">
                  {t("settings_store_integrate_buttonDesigner_mode", "Modo")}
                </label>
                <select
                  onChange={(e) => setInstructionsMode(e.target.value as "add")}
                  className="border p-2 w-full"
                  value={instructionsMode}
                >
                  <option value="add">
                    {t("settings_store_integrate_addAddToBasketButton_mode_add", "Add to basket mode")}{" "}
                  </option>
                  <option value="replace">
                    {t("settings_store_integrate_addAddToBasketButton_mode_replace", "Book now mode")}{" "}
                  </option>
                </select>
              </div>
            )}
          </div>
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-4">
              <label className="block text-sm font-medium">
                {t("settings_store_integrate_buttonDesigner_colors", "Colors")}
              </label>
              <div className="flex gap-3">
                <input
                  type="color"
                  value={bgColor}
                  onChange={(e) => setBgColor(e.target.value)}
                  className="w-full h-10"
                />
                <input
                  type="color"
                  value={textColor}
                  onChange={(e) => setTextColor(e.target.value)}
                  className="w-full h-10"
                />
                <input
                  type="color"
                  value={borderColor}
                  onChange={(e) => setBorderColor(e.target.value)}
                  className="w-full h-10"
                />
              </div>
            </div>
            <div className="col-span-8">
              <label className="block text-sm font-medium">
                {t("settings_store_integrate_addAddToBasketButton_padding", "Padding (px)")}
              </label>
              <div className="grid grid-cols-2 gap-3">
                <input
                  type="number"
                  value={paddingVertical}
                  onChange={(e) => setPaddingVertical(Number(e.target.value))}
                  className="border p-2"
                />
                <input
                  type="number"
                  value={paddingHorizontal}
                  onChange={(e) => setPaddingHorizontal(Number(e.target.value))}
                  className="border p-2"
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium">
                {t("settings_store_integrate_buttonDesigner_border_radius", "Border Radius")}
              </label>
              <input
                type="number"
                value={borderRadius}
                onChange={(e) => setBorderRadius(Number(e.target.value))}
                className="border p-2 w-full"
              />
            </div>
            <div>
              <label className="block text-sm font-medium">
                {t("settings_store_integrate_buttonDesigner_font_size", "Font Size")}
              </label>
              <input
                type="number"
                value={fontSize}
                onChange={(e) => setFontSize(Number(e.target.value))}
                className="border p-2 w-full"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="mt-4 flex items-center justify-center h-40 bg-gray-200 rounded-md">
            <button style={buttonStyle}>{buttonText}</button>
          </div>
          <div className="mt-4">
            <Code text={getButtonCode(variant)} className="text-xs" />
          </div>
        </div>
      </div>

      <div className="pt-3">
        <Button onClick={() => setShowAllCodes(!showAllCodes)} variant="link" className="px-0">
          {t("settings_store_integrate_productCodes_title", "See all product codes")}
        </Button>
        {showAllCodes && (
          <>
            {variants.map((variant) => (
              <div key={variant.id} className="hover:bg-secondary group flex items-center gap-6 border-b py-1">
                <div>
                  <img src={variant.image} className="max-h-10 max-w-10" />
                </div>
                <div className="w-96">
                  <div>{variant.name}</div>
                </div>
                <div>
                  <Code
                    text={getButtonCode(variant)}
                    copyButtonText={t("common_button_copy", "Copy")}
                    className="text-xs"
                  />
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );

  function getButtonCode(variant: (typeof variants)[0] | undefined) {
    if (!variant) {
      return "";
    }

    const clickEvent = (
      buttonType === "button" ? integrationScripts.buttonHandler : integrationScripts.addToBasketHandler
    )
      .replace("[PRODUCT_ID]", variant.productId)
      .replace("[VARIANT_ID]", variant.id)
      .replace("[REPLACE_BASKET]", instructionsMode === "replace" ? "true" : "false");

    const buttonStyleTxt = `background-color: ${bgColor}; border: 1px solid ${borderColor}; color: ${textColor}; padding: ${paddingVertical}px ${paddingHorizontal}px; border-radius: ${borderRadius}px; font-size: ${fontSize}px; cursor: pointer;`;
    const buttonCode = `<button onclick="${clickEvent}" style="${buttonStyleTxt}">${buttonText}</button>`;

    return buttonCode;
  }
}
