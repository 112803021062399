import { ArrowLeft } from "lucide-react";
import { Separator } from "../ui/separator";
import { Link } from "react-router-dom";
import { PropsWithChildren } from "react";
import { cn } from "@pulso/components/lib/utils";

export function PageHeader(
  props: PropsWithChildren<{
    title: string;
    subtitle: string;
    back?: { to: string; label: string };
    hideSeparator?: boolean;
    className?: string;
  }>
) {
  return (
    <>
      <div className={cn("flex flex-col items-start", props.className)}>
        <div className="flex items-center justify-between w-full">
          <div className="flex-1">
            {props.back && (
              <Link to={props.back.to} className="mb-1 flex items-center space-x-1 text-sm hover:bg-transparent px-0">
                <ArrowLeft size={16} /> <span>{props.back.label}</span>
              </Link>
            )}
            <div className="space-y-0.5">
              <h2 className="text-2xl font-bold tracking-tight">{props.title}</h2>
              <p className="text-muted-foreground">{props.subtitle}</p>
            </div>
          </div>
          <div className="ml-auto">{props.children}</div>
        </div>
      </div>
      {!props.hideSeparator && <Separator className="my-6" />}
    </>
  );
}
