import { Spinner } from "@/components/ui/spinner";
import { LayoutWithSidebar } from "@/layouts/LayoutWithSidebar";
import { t } from "i18next";
import { Rabbit } from "lucide-react";
import { useParams } from "react-router-dom";

export function SettingsPage() {
  const { facilityId } = useParams();

  const items = [
    {
      title: t("settings_facility_title", "Facility"),
      description: t("settings_facility_description", "Update the basic settings of your facility"),
      href: `/facility/${facilityId}/settings/facility`,
    },
    {
      title: t("settings_users_title", "Users"),
      description: t("settings_users_description", "Manage your staff"),
      href: `/facility/${facilityId}/settings/users`,
    },
    {
      title: t("settings_rentalPeriods_title", "Rental periods"),
      description: t("settings_rentalPeriods_description", "Define the periods and seasons used for all bookings"),
      href: `/facility/${facilityId}/settings/rental-periods`,
    },
    {
      title: t("settings_extraFields_title", "Extra fields"),
      description: t(
        "settings_extraFields_description",
        "Add extra information you would like to attach to your customers or bookings"
      ),
      href: `/facility/${facilityId}/settings/customer-fields`,
    },
    {
      title: t("settings_openingHours_title", "Opening hours"),
      description: t("settings_openingHours_description", "Define the opening hours for your facility"),
      href: `/facility/${facilityId}/settings/opening-hours`,
    },
    {
      title: t("settings_extraCharges_title", "Extra charges & Discounts"),
      description: t("settings_extraCharges_description", "Add commonly used extra charges and discounts"),
      href: `/facility/${facilityId}/settings/extra-charges`,
    },
    {
      title: t("documents_title", "Documents"),
      description: t("settings_documents_description", "Add documents that can be attached to a booking"),
      href: `/facility/${facilityId}/settings/documents`,
    },
    {
      title: t("settings_fastTrack_title", "Fast Track"),
      description: t(
        "settings_fastTrack_description",
        "Setup Fast Track, an app where your clients can create bookings from a shareable link"
      ),
      href: `/facility/${facilityId}/settings/fast-track`,
      icon: <Rabbit size={16} />,
    },
    {
      title: t("settings_agents_title", "Agents"),
      description: t("settings_agents_description", "Define commission scheme for agents that help your business"),
      href: `/facility/${facilityId}/settings/agents`,
    },
    {
      title: t("settings_store_title", "Online store"),
      description: t("settings_store_description", "Setup your online store and integrate it with your website"),
      href: `/facility/${facilityId}/settings/store`,
    },
    {
      title: t("settings_languages_title", "Languages"),
      description: t("settings_languages_description", "Translate your apps in the languages that your customers use"),
      href: `/facility/${facilityId}/settings/languages`,
    },
    // { title: t("settings_locations_title", "Locations"), href: `/facility/${facilityId}/settings/locations` },
  ];

  if (!facilityId) {
    return <Spinner />;
  }

  return <LayoutWithSidebar items={items} />;
}
