import { api } from "@/lib/api-client";
import { ApiObjects } from "@pulso/api-client";
import { useMutation } from "@/lib/useMutation";

export function useBookingFieldsUpdate(bookingId: string) {
  return useMutation({
    mutationFn: async (data: ApiObjects["UpdateBookingFieldsBody"]) => {
      await api.updateBookingFields({ bookingId }, data);
    },
    invalidateKeys: [["bookings"]],
    successMessage: "SAVE_SUCCESS",
  });
}
