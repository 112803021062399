import { useDocuments } from "@/api/useDocuments";
import { useProducts } from "@/api/useProducts";
import { UserAccount } from "@/components/specific/UserAccount";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { TooltipSimple } from "@/components/ui/tooltip";
import { isAdmin, isPulsoAdmin, useAuth } from "@/lib/useAuth";
import { cn } from "@/lib/utils";
import { DropdownMenuItem } from "@radix-ui/react-dropdown-menu";
import { t } from "i18next";
import {
  ListTreeIcon,
  Settings,
  Warehouse,
  Shield,
  Book,
  Calendar,
  ChevronDown,
  Notebook,
  LineChart,
  Users,
} from "lucide-react";
import { Link, NavLink, Outlet } from "react-router-dom";
import "./MainLayout.css";
import { SplashSpinner } from "@/components/ui/splash-spinner";
import { PropsWithChildren } from "react";
import { useHasRouteHandleData } from "@/lib/useHasRouteHandleData";
import { useHasRight } from "@/lib/useHasRight";

export function MainLayout() {
  const auth = useAuth();
  const products = useProducts();
  const { documents, isLoading: isDocumentLoading } = useDocuments();
  const showAccounting = documents.some((doc) => doc.poNumberFormat);
  const showOnlyIconsInSidebar = useHasRouteHandleData("showOnlyIconsInSidebar");
  const hasRightToSeeReports = useHasRight("seeReports");

  if (products.isLoading || isDocumentLoading) {
    return <SplashSpinner />;
  }

  const navigationItems = [
    {
      name: t("menu_bookings", { defaultValue: "Bookings" }),
      href: `/facility/${auth.facility?.id}/bookings`,
      icon: Notebook,
    },
    {
      name: t("menu_calendar", { defaultValue: "Calendar" }),
      href: `/facility/${auth.facility?.id}/calendar`,
      icon: Calendar,
      beta: true,
    },
    {
      name: t("menu_products", { defaultValue: "Products" }),
      href: `/facility/${auth.facility?.id}/products`,
      icon: Warehouse,
    },
    ...(showAccounting
      ? [
          {
            name: t("menu_accounting", { defaultValue: "Accounting" }),
            href: `/facility/${auth.facility?.id}/documents`,
            icon: Book,
          },
        ]
      : []),
    ...(hasRightToSeeReports
      ? [
          {
            name: t("menu_reports", "Reports"),
            href: `/facility/${auth.facility?.id}/reports/daily`,
            icon: LineChart,
          },
        ]
      : []),
    { name: t("menu_customers", "Customers"), href: `/facility/${auth.facility?.id}/customers`, icon: Users },
    ...(isAdmin()
      ? [
          {
            name: t("menu_settings", { defaultValue: "Settings" }),
            href: `/facility/${auth.facility?.id}/settings/facility`,
            icon: Settings,
          },
        ]
      : []),
    ...(isPulsoAdmin()
      ? [
          {
            name: "Admin",
            href: `/admin`,
            icon: Shield,
            pullDown: true,
          },
        ]
      : []),
  ];

  return (
    <div className="h-screen-dvh flex flex-col">
      <div className="h-14 flex items-center bg-primary text-white pl-3 sm:px-1.5 flex-shrink-0 overflow-hidden">
        <div className="hidden sm:block mr-3 md:mr-7 flex-shrink-0">
          <Link to={`/facility/${auth.facilityId}/bookings`} state={t("menu_bookings")}>
            <img className="ml-1" width={50} src="/logo.png" />
          </Link>
        </div>
        <div>
          <Link to={`/facility/${auth.facilityId}/bookings`} state={t("menu_bookings")}>
            {auth.facility?.organization.name}
          </Link>
        </div>
        <div>
          {auth.facilities.length > 1 ? (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <div className="px-3 font-thin text-white flex items-center justify-center cursor-pointer">
                  {auth.facility?.name} <ChevronDown className="ml-1" size={14} />
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {auth.facilities.map((facility) => (
                  <DropdownMenuItem
                    key={facility.id}
                    className="py-1.5 px-3 cursor-pointer hover:bg-gray-100 outline-none"
                    onClick={() => (window.location.href = `/facility/${facility.id}/bookings`)}
                  >
                    {facility.name}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          ) : (
            <div className="px-3 font-thin text-white">{auth.facility?.name}</div>
          )}
        </div>
        <div className="ml-auto">
          <UserAccount />
        </div>
      </div>
      <div className="flex w-full flex-1 overflow-hidden">
        {/* Static sidebar for desktop */}
        <div
          className={cn(
            "h-full inset-y-0 z-50 flex-shrink-0 hidden md:flex flex-col bg-secondary pt-4 transition-all w-16",
            !showOnlyIconsInSidebar && "2xl:w-44"
          )}
        >
          {navigationItems.map((navigationItem) => (
            <TooltipSimple
              key={navigationItem.href}
              text={navigationItem.name}
              side="right"
              className={cn(navigationItem.pullDown && "mt-auto")}
            >
              <NavLink
                key={navigationItem.name}
                to={navigationItem.href}
                state={navigationItem.name}
                className={({ isActive }) =>
                  cn(
                    "icon-sidebar-menu-item group flex items-center px-3 py-2 rounded-md cursor-pointer text-[#493f2d]",
                    isActive && "icon-sidebar-menu-item--active"
                  )
                }
              >
                <div className="icon-sidebar-menu-item__icon p-2 rounded-md relative">
                  <navigationItem.icon className="h-6 w-6" strokeWidth={1.2}></navigationItem.icon>
                  {navigationItem.beta && (
                    <div
                      className="bg-yellow-400 rounded-2xl px-1 absolute -top-1.5 -right-2"
                      style={{ fontSize: "8px" }}
                    >
                      beta
                    </div>
                  )}
                </div>
                <span className={cn("pl-2 hidden", !showOnlyIconsInSidebar && "2xl:inline")}>
                  {navigationItem.name}
                </span>
              </NavLink>
            </TooltipSimple>
          ))}
        </div>
        <main className="h-full bg-secondary/50 flex-1 overflow-y-auto min-w-[360px]" id="main">
          <Outlet />
        </main>
      </div>
    </div>
  );
}

MainLayout.Padding = function MainLayoutPadding(props: PropsWithChildren) {
  return <div className="p-3 sm:p-6 h-full flex flex-col">{props.children}</div>;
};

MainLayout.PaddingFullHeight = function MainLayoutPadding(props: PropsWithChildren) {
  return <div className="p-3 sm:p-6 h-full">{props.children}</div>;
};
