import { ApiObjects } from "@pulso/api-client";
import { ChevronDown } from "lucide-react";
import { PropsWithChildren, useState } from "react";
import { ConfirmDialog } from "../ui/confirm-dialog";
import { useTranslation } from "react-i18next";
import { useBookingDeliver } from "@/api/useBookingDeliver";
import { useBookingReturn } from "@/api/useBookingReturn";
import { BookingStatus } from "./BookingStatus";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { ReturnBookingConfirmationModal } from "@/pages/bookings/details/ReturnBookingConfirmationModal";
import { useBookingConfirm } from "@/api/useBookingConfirm";
import { useBookingCancel } from "@/api/useBookingCancel";
import { useBookingUnconfirm } from "@/api/useBookingUnconfirm";
import { useBookingHold } from "@/api/useBookingHold";
import {
  Dialog,
  DialogFooter,
  DialogHeader,
  DialogDescription,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { Checkbox } from "../ui/checkbox";
import { Textarea } from "../ui/textarea";
import { ButtonLoadable } from "../ui/button-loadable";
import { useBookingStatusAllowedTransitions } from "@/lib/useBookingStatusAllowedTransitions";

type BookingStatusButtonProps = {
  booking: ApiObjects["BookingDto"];
  readOnly?: boolean;
  className?: string;
};

export function BookingStatusButton({ booking, readOnly, className }: BookingStatusButtonProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const allowedStatusTransitions = useBookingStatusAllowedTransitions(booking.facilityId, booking.status);

  if (readOnly) {
    return <BookingStatus status={booking.status} />;
  }

  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger>
          <BookingStatus status={booking.status} className={className}>
            <ChevronDown size={16} />
          </BookingStatus>
        </PopoverTrigger>
        <PopoverContent className="w-auto">
          <div className="flex flex-col gap-2">
            <span className="text-sm">{t("bookings_contextMenu_changeStatus_label", "Set as:")}</span>
            {allowedStatusTransitions.map((toStatus) => (
              <StatusChangeElement key={toStatus} booking={booking} toStatus={toStatus} />
            ))}
          </div>
        </PopoverContent>
      </Popover>
    </>
  );
}

export function StatusChangeElement({
  toStatus,
  booking,
}: {
  toStatus: ApiObjects["BookingDto"]["status"];
  booking: ApiObjects["BookingDto"];
}) {
  return (
    <>
      {toStatus === "ON_HOLD" ? (
        <OnHoldItem booking={booking} />
      ) : toStatus === "UNCONFIRMED" ? (
        <UnconfirmedItem booking={booking} />
      ) : toStatus === "PENDING" ? (
        <PendingItem booking={booking} />
      ) : toStatus === "IN_PROGRESS" ? (
        <InProgressItem booking={booking} />
      ) : toStatus === "COMPLETED" ? (
        <CompletedItem booking={booking} />
      ) : toStatus === "CANCELLED" ? (
        <CancelledItem booking={booking} />
      ) : null}
    </>
  );
}

function OnHoldItem({ booking }: { booking: ApiObjects["BookingDto"] }) {
  const mutation = useBookingHold(booking.id);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        mutation.mutate();
      }}
      className="cursor-pointer"
    >
      <BookingStatusWrap isLoading={mutation.isPending} status="ON_HOLD" />
    </div>
  );
}

function UnconfirmedItem({ booking }: { booking: ApiObjects["BookingDto"] }) {
  const mutation = useBookingUnconfirm(booking.id);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        mutation.mutate();
      }}
      className="cursor-pointer"
    >
      <BookingStatusWrap isLoading={mutation.isPending} status="UNCONFIRMED" />
    </div>
  );
}

function PendingItem({ booking }: { booking: ApiObjects["BookingDto"] }) {
  const mutation = useBookingConfirm(booking.id);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        mutation.mutate();
      }}
      className="cursor-pointer"
    >
      <BookingStatusWrap isLoading={mutation.isPending} status="PENDING" />
    </div>
  );
}

function InProgressItem({ booking }: { booking: ApiObjects["BookingDto"] }) {
  const { t } = useTranslation();
  const mutation = useBookingDeliver(booking.id, booking.items.length);

  if (booking.status === "PENDING") {
    return (
      <ConfirmDialog
        title={t("bookings_details_deliver_confirmation_title", "Deliver booking")}
        description={t(
          "bookings_details_deliver_confirmation_description",
          "Did you deliver all items of this booking?"
        )}
        okButtonText={t("common_button_yes", "Yes")}
        onContinue={() => mutation.mutate()}
      >
        <div onClick={(e) => e.stopPropagation()} className="cursor-pointer">
          <BookingStatusWrap isLoading={mutation.isPending} status="IN_PROGRESS" />
        </div>
      </ConfirmDialog>
    );
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        mutation.mutate();
      }}
      className="cursor-pointer"
    >
      <BookingStatusWrap status="IN_PROGRESS" isLoading={mutation.isPending} />
    </div>
  );
}

function CompletedItem({ booking }: { booking: ApiObjects["BookingDto"] }) {
  const mutation = useBookingReturn(booking.id);

  return (
    <ReturnBookingConfirmationModal hasDeposit={!!booking.invoice.deposit} onContinue={() => mutation.mutate()}>
      <div onClick={(e) => e.stopPropagation()} className="cursor-pointer">
        <BookingStatusWrap status="COMPLETED" isLoading={mutation.isPending} />
      </div>
    </ReturnBookingConfirmationModal>
  );
}

function CancelledItem({ booking }: { booking: ApiObjects["BookingDto"] }) {
  return (
    <CancelBookingDialog booking={booking}>
      <div onClick={(e) => e.stopPropagation()} className="cursor-pointer">
        <BookingStatusWrap status="CANCELLED" isLoading={false} />
      </div>
    </CancelBookingDialog>
  );
}

function BookingStatusWrap({ status, isLoading }: { status: ApiObjects["BookingDto"]["status"]; isLoading: boolean }) {
  return <BookingStatus className="flex w-full h-8" status={status} isLoading={isLoading} />;
}

function CancelBookingDialog(props: PropsWithChildren<{ booking: ApiObjects["BookingDto"] }>) {
  const { t } = useTranslation();
  const mutation = useBookingCancel(props.booking.id);
  const [notify, setNotify] = useState(false);
  const [reason, setReason] = useState("");

  return (
    <Dialog>
      <DialogTrigger asChild>{props.children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t("booking_statusButton_cancelModal_title", "Cancel booking of {{customerName}}", {
              customerName: props.booking.customer.name,
            })}
          </DialogTitle>
          <DialogDescription>
            {t(
              "booking_statusButton_cancelModal_description",
              "You are about to cancel the booknig of {{customerName}}",
              {
                customerName: props.booking.customer.name,
              }
            )}
          </DialogDescription>
        </DialogHeader>
        {!props.booking.customer.email ? (
          <div></div>
        ) : (
          <form className="flex flex-col gap-6">
            <div className="text-sm">
              {t(
                "booking_statusButton_cancelModal_info",
                "Would you like to send {{customerName}} a cancellation e-mail?",
                {
                  customerName: props.booking.customer.name,
                }
              )}
            </div>
            <div className="flex items-center gap-1">
              <Checkbox id="notifyOnCancelCheckbox" checked={notify} onCheckedChange={(v) => setNotify(v === true)} />
              <label className="text-sm" htmlFor="notifyOnCancelCheckbox">
                {t("booking_statusButton_cancelModal_notify_label", "Send an email notification")}
              </label>
            </div>

            <div className="flex items-center gap-1">
              <Textarea
                placeholder={t("booking_statusButton_cancelModal_reason_label", "Add a message for {{customerName}}", {
                  customerName: props.booking.customer.name,
                })}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                disabled={!notify}
              ></Textarea>
            </div>
          </form>
        )}
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("common_button_close")}</Button>
          </DialogClose>
          <ButtonLoadable
            isLoading={mutation.isPending}
            onClick={() => {
              mutation.mutate({ notifyCustomer: notify, reason });
            }}
          >
            {notify
              ? t("booking_statusButton_cancelModal_cancelWithEmail_button", "Cancel booking & send email")
              : t("booking_statusButton_cancelModal_cancelNoEmail_button", "Cancel booking")}
          </ButtonLoadable>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
