import { Spinner } from "@/components/ui/spinner";
import { CustomFieldsList } from "../customer-fields/CustomFieldsList";
import { useBookingCustomField } from "@/api/useBookingCustomFields";
import { useBookingCustomFieldCreate } from "@/api/useBookingCustomFieldsCreate";
import { useBookingCustomFieldUpdate } from "@/api/useBookingCustomFieldsUpdate";
import { useBookingCustomFieldDelete } from "@/api/useBookingCustomFieldsDelete";

type BookingCustomFieldsListProps = {
  facilityId: string;
};

export function BookingCustomFieldsList(props: BookingCustomFieldsListProps) {
  const { fields, isLoading } = useBookingCustomField(props.facilityId);
  const createMutation = useBookingCustomFieldCreate(props.facilityId);
  const deleteField = useBookingCustomFieldDelete();
  const updateField = useBookingCustomFieldUpdate();

  if (!props.facilityId) {
    return <Spinner />;
  }

  return (
    <CustomFieldsList
      facilityId={props.facilityId}
      fields={fields}
      isLoading={isLoading}
      onCreate={(data, onSuccess) => createMutation.mutate(data, { onSuccess })}
      onDelete={deleteField.mutate}
      onUpdate={(data, onSuccess) => updateField.mutate(data, { onSuccess })}
      isCreating={createMutation.isPending}
      isUpdating={updateField.isPending}
      forBooking
    />
  );
}
