import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";
import { ApiObjects } from "@pulso/api-client";

export function useBookingCustomFieldCreate(facilityId: string | undefined) {
  return useMutation({
    mutationFn: async (data: ApiObjects["CustomerFieldCreateBody"]) => {
      return await api.createBookingCustomField(
        {
          facilityId: facilityId || "",
        },
        data
      );
    },
    invalidateKeys: [["booking-custom-fields"]],
    successMessage: "SAVE_SUCCESS",
  });
}
