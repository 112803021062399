import { api } from "@/lib/api-client";
import { useMutationWithAuth } from "@/lib/useMutationWithAuth";
import { ApiObjects } from "@pulso/api-client";

export function useProductCreate() {
  return useMutationWithAuth<{ id: string }, void, ApiObjects["CreateProductBody"]>({
    mutationFn: async (facilityId, data) => {
      return await api.createProduct({ facilityId }, data);
    },
    invalidateKeys: [["bookings"], ["products"], ["stock-items"]],
  });
}
