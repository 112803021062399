import { Price } from "@pulso/components/lib/Price";
import { Badge } from "@/components/ui/badge";
import { Separator } from "@/components/ui/separator";
import { AddPenaltyModal } from "./AddPenaltyModal";
import { ApiObjects } from "@pulso/api-client";
import { AddDiscountModal } from "./AddDiscountModal";
import { AddPaymentModal } from "./AddPaymentModal";
import { Info, InfoIcon, Trash2 } from "lucide-react";
import { useBookingInvoiceItemDelete } from "@/api/useBookingInvoiceItemDelete";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { PropsWithChildren } from "react";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { t } from "i18next";
import { InvoiceName } from "./InvoiceName";
import { cn } from "@/lib/utils";
import { AddDepositModal } from "./AddDepositModal";
import { Nl2br } from "@pulso/components/lib/Nl2br";

type BookingInvoiceItemsProps = {
  booking: ApiObjects["BookingDto"];
};

export function BookingInvoiceItems({ booking }: BookingInvoiceItemsProps) {
  const deleteItem = useBookingInvoiceItemDelete(booking.id);

  return (
    <div>
      <div>
        <div className="flex justify-between space-x-3">
          <span className="">{t("booking_invoice_penalties_title", "Penalties")}</span>
          <span>
            <Price price={booking.invoice.totalPeanlty} currency={booking.currency} />
          </span>
        </div>
        <Separator className="my-3" />
        {booking.invoice.penalties.map((penalty, i) => (
          <Item
            key={penalty.id}
            id={penalty.id}
            onDelete={deleteItem.mutate}
            amount={penalty.amount}
            notes={penalty.notes}
            label={t("booking_invoice_penalties_item", "Penalty")}
            currency={booking.currency}
          >
            {InvoiceName(t, penalty.reason)}
          </Item>
        ))}
        <AddPenaltyModal bookingId={booking.id} facilityId={booking.facilityId} currency={booking.currency}>
          <div>
            <Badge variant="secondary" className="cursor-pointer whitespace-normal">
              + {t("booking_invoice_penalties_add_button", "Add penalty")}
            </Badge>
          </div>
        </AddPenaltyModal>
      </div>
      <div className="mt-12">
        <div className="flex justify-between space-x-3">
          <span className="">{t("booking_invoice_discounts_title", "Discounts")}</span>
          <span>
            <Price price={booking.invoice.totalDiscount} currency={booking.currency} />
          </span>
        </div>
        <Separator className="my-3" />
        {booking.invoice.discounts.map((discount, i) => (
          <Item
            key={discount.id}
            id={discount.id}
            onDelete={deleteItem.mutate}
            amount={discount.amount}
            notes={discount.notes}
            label={t("booking_invoice_discounts_item", "Discount")}
            currency={booking.currency}
          >
            {InvoiceName(t, discount.reason)}
          </Item>
        ))}
        <AddDiscountModal facilityId={booking.facilityId} bookingId={booking.id} currency={booking.currency}>
          <div>
            <Badge variant="secondary" className="cursor-pointer whitespace-normal">
              + {t("booking_invoice_discounts_add_button", "Add discount")}
            </Badge>
          </div>
        </AddDiscountModal>
      </div>
      <div className="mt-12">
        <div className="flex justify-between space-x-3">
          <span className="">{t("booking_invoice_payments_title", "Payments")}</span>
          <span>
            <Price price={booking.invoice.totalPayment} currency={booking.currency} />
          </span>
        </div>
        <Separator className="my-3" />
        {booking.invoice.payments.map((payment, i) => (
          <Item
            key={payment.id}
            id={payment.id}
            onDelete={deleteItem.mutate}
            amount={payment.amount}
            notes={payment.concept}
            label={t("booking_invoice_payments_item", "Payment")}
            currency={booking.currency}
          >
            {InvoiceName(t, payment.method)}
          </Item>
        ))}
        <AddPaymentModal bookingId={booking.id} duePayment={booking.invoice.totalDue} currency={booking.currency}>
          <div>
            <Badge variant="secondary" className="cursor-pointer whitespace-normal">
              + {t("booking_invoice_payments_add_button", "Add payment")}
            </Badge>
          </div>
        </AddPaymentModal>
      </div>
      <div className="mt-12">
        <div className="flex justify-between space-x-3">
          <span className="">{t("booking_invoice_deposit_title", "Deposit")}</span>
          <span>
            {booking.invoice.deposit && <Price price={booking.invoice.deposit.amount} currency={booking.currency} />}
          </span>
        </div>
        <Separator className="my-3" />
        {booking.invoice.deposit && (
          <Item
            id={booking.invoice.deposit.id}
            onDelete={deleteItem.mutate}
            amount={booking.invoice.deposit.amount}
            notes={booking.invoice.deposit.concept}
            label={t("booking_invoice_payments_item", "Payment")}
            currency={booking.currency}
          >
            {InvoiceName(t, booking.invoice.deposit.method)}
          </Item>
        )}
        {booking.invoice.deposit ? null : (
          <AddDepositModal bookingId={booking.id} currency={booking.currency}>
            <div>
              <Badge variant="secondary" className="cursor-pointer whitespace-normal">
                + {t("booking_invoice_deposit_add_button", "Add deposit")}
              </Badge>
            </div>
          </AddDepositModal>
        )}
      </div>
      <div className="mt-12 flex justify-between space-x-3 font-bold">
        <div>{t("booking_invoice_totalDue", "Total due")}</div>
        <div>
          <Price price={booking.invoice.totalDue} currency={booking.currency} />
        </div>
      </div>
    </div>
  );
}

function Item({
  amount,
  label,
  id,
  notes,
  currency,
  onDelete,
  children,
}: PropsWithChildren<{
  amount: number;
  label: string;
  currency: string;
  notes?: string | null;
  id: string;
  onDelete: (id: string) => void;
}>) {
  return (
    <div className="flex items-start text-sm mb-3 space-x-6">
      <div className="flex-1 overflow-hidden">
        <div>{children}</div>

        <Tooltip>
          <TooltipTrigger asChild>
            <div className={cn("text-xs flex text-muted-foreground invisible", notes && "visible")}>
              <div className="mr-1 pt-0.5">
                <InfoIcon size={12} />
              </div>
              <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                <Nl2br text={notes} />
              </span>
            </div>
          </TooltipTrigger>
          <TooltipContent>{notes}</TooltipContent>
        </Tooltip>
      </div>
      <div className="ml-auto flex items-center">
        <div>
          <Price price={amount} currency={currency} />
        </div>
        <div>
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Trash2 size={15} className="ml-2 hover:text-gray-400 cursor-pointer" />
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>
                  {t("bookings_invoices_delete_title", "Delete {{label}}", { label })}
                </AlertDialogTitle>
                <AlertDialogDescription>
                  {t("bookings_invoices_delete_subtitle", "Are you sure you want to delete this {{label}}?", { label })}
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>{t("common_button_cancel")}</AlertDialogCancel>
                <AlertDialogAction onClick={() => onDelete(id)}>{t("common_button_delete")}</AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </div>
    </div>
  );
}
