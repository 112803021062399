import parsePhoneNumber, { getCountries, getCountryCallingCode, isValidNumber } from "libphonenumber-js";
import { useEffect, useRef, useState } from "react";
import { LanguageFlagSprite } from "./LanguageFlagSprite";
import { getName } from "country-list";
import { z } from "zod";
import { cn } from "./utils";

type PhoneInputProps = {
  value: any;
  onChange: (phone: string) => void;
  disabled?: boolean;
  invalid?: boolean;
};

const CODES = getAllCountries();

export function PhoneInput(props: PhoneInputProps) {
  const [code, setCode] = useState<string | undefined>("ES");
  const [phone, setPhone] = useState<string | undefined>("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.value) {
      const fullMatchingCode = Object.values(CODES).find((country) => country.phoneCode === props.value);
      if (fullMatchingCode && fullMatchingCode.countryCode) {
        setCode(fullMatchingCode.countryCode);
      } else {
        const parsed = parsePhoneNumber(props.value);

        if (parsed && parsed.country && CODES[parsed.country]) {
          setCode(parsed.country);
          setPhone(parsed.nationalNumber);
        }
      }
    }
  }, [props.value]);

  return (
    <div
      className={cn(
        "flex items-center overflow-hidden h-9 has-[:focus-visible]:border-gray-700 border border-input rounded-md",
        props.invalid && "border-red-500 focus-visible:border-red-500"
      )}
    >
      <div className="h-full text-sm relative">
        <label className="flex gap-1 items-center px-3 h-full border-r rounded-l-md cursor-pointer peer-focus-visible:bg-gray-100 bg-white">
          {code && CODES[code] ? (
            <>
              <LanguageFlagSprite lang={CODES[code].countryCode} /> {CODES[code].phoneCode}
            </>
          ) : (
            "..."
          )}
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 opacity-50"
          >
            <path
              d="M4.93179 5.43179C4.75605 5.60753 4.75605 5.89245 4.93179 6.06819C5.10753 6.24392 5.39245 6.24392 5.56819 6.06819L7.49999 4.13638L9.43179 6.06819C9.60753 6.24392 9.89245 6.24392 10.0682 6.06819C10.2439 5.89245 10.2439 5.60753 10.0682 5.43179L7.81819 3.18179C7.73379 3.0974 7.61933 3.04999 7.49999 3.04999C7.38064 3.04999 7.26618 3.0974 7.18179 3.18179L4.93179 5.43179ZM10.0682 9.56819C10.2439 9.39245 10.2439 9.10753 10.0682 8.93179C9.89245 8.75606 9.60753 8.75606 9.43179 8.93179L7.49999 10.8636L5.56819 8.93179C5.39245 8.75606 5.10753 8.75606 4.93179 8.93179C4.75605 9.10753 4.75605 9.39245 4.93179 9.56819L7.18179 11.8182C7.35753 11.9939 7.64245 11.9939 7.81819 11.8182L10.0682 9.56819Z"
              fill="currentColor"
              fillRule="evenodd"
              clipRule="evenodd"
            ></path>
          </svg>
        </label>
        <select
          className="left-0 top-0 bottom-0 right-0 absolute cursor-pointer peer"
          style={{ opacity: "0.01" }}
          value={code}
          onChange={(e) => onCodeChange(e.target.value)}
          disabled={props.disabled}
          name="country-code"
        >
          {Object.entries(CODES).map(([code, codeData]) => (
            <option key={code} value={codeData.countryCode}>
              {codeData.label} ({codeData.phoneCode})
            </option>
          ))}
        </select>
      </div>
      <div className="h-full flex-1">
        <input
          ref={inputRef}
          className="h-full w-full rounded-r-md bg-white px-3 py-1 text-sm shadow-sm transition-colors placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50"
          value={phone}
          onChange={(e) => onPhoneChange(e.target.value)}
          disabled={props.disabled}
          name="phone"
          autoComplete="off"
        />
      </div>
    </div>
  );

  function onCodeChange(newCode: string) {
    setCode(newCode);
    const phoneCode = CODES[newCode]?.phoneCode;
    if (inputRef.current?.value && phoneCode) {
      props.onChange(phoneCode + phone);
    }
    inputRef.current?.focus();
  }

  function onPhoneChange(newPhone: string) {
    setPhone(newPhone);
    const phoneCode = code ? CODES[code]?.phoneCode : null;

    if (newPhone === "") {
      props.onChange("");
    } else if (newPhone.startsWith("+") || newPhone.startsWith("00")) {
      props.onChange(newPhone.replace(/^00/, "+"));
    } else if (phoneCode) {
      props.onChange(phoneCode + newPhone);
    }
  }
}

export const zodPhone = (options?: { optional?: boolean }) => {
  const field = options?.optional ? z.string().optional() : z.string();

  return field.superRefine((phone: string | undefined, ctx): void => {
    if (typeof phone === "string") {
      if (!Boolean(options?.optional) && !phone) {
        ctx.addIssue({ code: z.ZodIssueCode.custom, path: [], message: "Required" });
      } else if (phone && !isValidNumber(phone)) {
        ctx.addIssue({ code: z.ZodIssueCode.custom, path: [], message: "Invalid phone" });
      }
    }
  });
};

function getAllCountries() {
  const allCountries = getCountries()
    .map((countryCode) => ({
      phoneCode: "+" + getCountryCallingCode(countryCode),
      label: getName(countryCode) || "",
      countryCode,
    }))
    .filter((c) => Boolean(c.label))
    .filter((c) => c.countryCode !== "VA");
  allCountries.sort((a, b) => (a.label < b.label ? -1 : 1));

  const CODES: Record<string, { label: string; countryCode: string; phoneCode: string }> = allCountries.reduce(
    (obj, country) => ({
      ...obj,
      [country.countryCode]: country,
    }),
    {}
  );

  CODES["XK"] = {
    countryCode: "XK",
    label: "Kosovo",
    phoneCode: "+383",
  };

  return CODES;
}
