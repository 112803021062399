import { useBookingsTraceLog } from "@/api/useBookingTraceLog";
import { BookingStatus } from "@/components/specific/BookingStatus";
import { Price } from "@pulso/components/lib/Price";
import { CentralSpinner } from "@/components/ui/central-spinner";
import { Separator } from "@/components/ui/separator";
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from "@/components/ui/sheet";
import { ApiObjects } from "@pulso/api-client";
import { formatDate, MailType } from "@pulso/utils";
import {
  ArrowRight,
  Calendar,
  ClipboardPenLine,
  DollarSign,
  History,
  LucideIcon,
  Mail,
  NotebookPen,
  PackageMinus,
  PackagePlus,
  RefreshCcw,
  ScanBarcode,
  Sparkle,
  Trash2,
  Unplug,
  User,
  Scan,
  List,
} from "lucide-react";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { InvoiceName } from "../InvoiceName";
import { cn } from "@pulso/components/lib/utils";
import { FieldValue } from "@pulso/components/lib/FieldValue";
import { useBookingCustomField } from "@/api/useBookingCustomFields";

export function BookingTraceLogSheet(props: PropsWithChildren<{ booking: ApiObjects["BookingDto"] }>) {
  const { t } = useTranslation();

  return (
    <Sheet>
      <SheetTrigger asChild>{props.children}</SheetTrigger>
      <SheetContent className="sm:max-w-[400px] h-screen flex flex-col">
        <SheetHeader>
          <SheetTitle className="flex items-center gap-2">
            <History size={16} /> {t("booking_traceLog_title", "Booking history")}
          </SheetTitle>
          <SheetDescription>
            {t("booking_traceLog_description", "Recorded history for the booking of {{customerName}}", {
              customerName: props.booking.customer.name,
            })}
          </SheetDescription>
        </SheetHeader>
        <Separator className="my-0" />
        <div className="p-6 overflow-y-auto">
          <BookingTraceLog
            bookingId={props.booking.id}
            facilityId={props.booking.facilityId}
            currency={props.booking.currency}
          />
        </div>
      </SheetContent>
    </Sheet>
  );
}

export function BookingTraceLog(props: { bookingId: string; facilityId: string; currency: string }) {
  const { t } = useTranslation();
  const historyRecordingStartDate = "2024-08-16";
  const { entries, isLoading } = useBookingsTraceLog(props.bookingId);
  const { fields: bookingCustomFields } = useBookingCustomField(props.facilityId);
  const hasMissingEntries = entries.length === 0 || entries[0].entry.type !== "bookingCreated";

  if (!entries || isLoading) {
    return <CentralSpinner />;
  }

  const icons: Record<ApiObjects["BookingTraceLogEntryDto"]["entry"]["type"], LucideIcon> = {
    bookingCreated: Sparkle,
    statusChanged: RefreshCcw,
    emailSent: Mail,
    itemAdded: PackagePlus,
    itemRemoved: PackageMinus,
    agentAdded: User,
    notesChanged: NotebookPen,
    scheduleChanged: Calendar,
    invoiceItemAdded: DollarSign,
    invoiceItemRemoved: Trash2,
    documentSigned: ClipboardPenLine,
    bookingItemAssigned: ScanBarcode,
    bookingItemUnassigned: Scan,
    bookingFieldsUpdated: List,
  };

  return (
    <div className="flex flex-col">
      {hasMissingEntries && (
        <Entry icon={Unplug} user={"System"} date={historyRecordingStartDate}>
          <EntryFirstLine>
            <div className="font-medium">
              {t("booking_traceLog_unavailableForOldBookings_title", "Booking history recording started", {
                date: formatDate(historyRecordingStartDate, null, undefined, "date"),
              })}
            </div>
            <Bubble>
              {t(
                "booking_traceLog_unavailableForOldBookings_info",
                "The recording of booking history started on {{date}}. Therefore, events that happend before this date are not recorded.",
                {
                  date: formatDate(historyRecordingStartDate, null, undefined, "date"),
                }
              )}
            </Bubble>
          </EntryFirstLine>
        </Entry>
      )}
      {entries.map((item, i) => (
        <Entry key={i} icon={icons[item.entry.type]} date={item.entry.createdAt} user={item.entry.user}>
          {item.entry.type === "statusChanged" ? (
            <StatusChangedLogEntry entry={item.entry} />
          ) : item.entry.type === "bookingCreated" ? (
            <EntryFirstLine>
              <span className="font-medium">
                {t("booking_traceLog_bookingCreated_label", `Booking created via {{source}}`, {
                  source: getCreatedViaTranslation(t, item.entry.createdVia),
                })}
              </span>
            </EntryFirstLine>
          ) : item.entry.type === "documentSigned" ? (
            <>
              <EntryFirstLine>
                <span className="font-medium">{t("booking_traceLog_documentSigned_label", `Document signed`)}</span>
                <div className="text-sm">{item.entry.document}</div>
              </EntryFirstLine>
            </>
          ) : item.entry.type === "itemAdded" ? (
            <>
              <EntryFirstLine>
                <span className="font-medium">{t("booking_traceLog_itemAdded_label", `Product added`)}</span>
                <div className="text-sm">{item.entry.name}</div>
              </EntryFirstLine>
            </>
          ) : item.entry.type === "itemRemoved" ? (
            <EntryFirstLine>
              <span className="font-medium">{t("booking_traceLog_itemRemoved_label", `Product removed`)}</span>
              <div className="text-sm line-through">{item.entry.name}</div>
            </EntryFirstLine>
          ) : item.entry.type === "emailSent" ? (
            <EntryFirstLine>
              <span className="font-medium">{t("booking_traceLog_emailSent_label", `Email sent`)}</span>
              <div className="text-sm">
                {getMailTranslation(t, item.entry.emailType)}
                {item.entry.param && <span className="text-sm">: {item.entry.param}</span>}
              </div>
            </EntryFirstLine>
          ) : item.entry.type === "agentAdded" ? (
            <>
              <EntryFirstLine>
                <span className="font-medium">{t("booking_traceLog_agentChanged_label", "Agent changed")}</span>
              </EntryFirstLine>
              <div className="line-through text-sm">
                {item.entry.oldAgentId || t("booking_traceLog_agentChanged_noAgent", "No agent")}
              </div>
              <div className="text-sm">
                {item.entry.newAgentId || t("booking_traceLog_agentChanged_noAgent", "No agent")}
              </div>
            </>
          ) : item.entry.type === "notesChanged" ? (
            <>
              <EntryFirstLine>
                <span className="font-medium">{t("booking_traceLog_notesChanged_label", "Notes changed")}</span>
              </EntryFirstLine>
              <Bubble>
                {item.entry.newNotes || (
                  <span className="italic">{t("booking_traceLog_notesChanged_noNotes", "No notes")}</span>
                )}
              </Bubble>
            </>
          ) : item.entry.type === "scheduleChanged" ? (
            <>
              <EntryFirstLine>
                <span className="font-medium">{t("booking_traceLog_scheduleChanged_label", "Rescheduled")}</span>
              </EntryFirstLine>
              <div className="text-sm line-through">
                {formatDate(item.entry.oldStartAt, null, undefined, "date-time")} -{" "}
                {formatDate(item.entry.oldEndAt, null, undefined, "date-time")}
              </div>
              <div className="text-sm">
                {formatDate(item.entry.newStartAt, null, undefined, "date-time")} -{" "}
                {formatDate(item.entry.newEndAt, null, undefined, "date-time")}
              </div>
            </>
          ) : item.entry.type === "invoiceItemAdded" ? (
            <>
              <EntryFirstLine>
                <span className="font-medium">
                  {item.entry.invoiceItemType === "PAYMENT"
                    ? t("booking_traceLog_invoiceItemAdded_payment", "Payment received")
                    : item.entry.invoiceItemType === "DEPOSIT"
                      ? t("booking_traceLog_invoiceItemAdded_deposit", "Deposit taken")
                      : item.entry.invoiceItemType === "DISCOUNT"
                        ? t("booking_traceLog_invoiceItemAdded_payment", "Discount added")
                        : item.entry.invoiceItemType === "PENALTY"
                          ? t("booking_traceLog_invoiceItemAdded_penalty", "Extra charge added")
                          : null}
                </span>
                : <Price price={item.entry.amount} currency={props.currency} /> ({InvoiceName(t, item.entry.reason)})
              </EntryFirstLine>
              {item.entry.notes && <Bubble>{item.entry.notes}</Bubble>}
            </>
          ) : item.entry.type === "invoiceItemRemoved" ? (
            <>
              <EntryFirstLine>
                <span className="font-medium">
                  {item.entry.invoiceItemType === "PAYMENT"
                    ? t("booking_traceLog_invoiceItemRemoved_payment", "Payment removed")
                    : item.entry.invoiceItemType === "DEPOSIT"
                      ? t("booking_traceLog_invoiceItemRemoved_deposit", "Deposit returned")
                      : item.entry.invoiceItemType === "DISCOUNT"
                        ? t("booking_traceLog_invoiceItemRemoved_payment", "Discount removed")
                        : item.entry.invoiceItemType === "PENALTY"
                          ? t("booking_traceLog_invoiceItemRemoved_penalty", "Extra charge removed")
                          : null}
                </span>
                : <Price price={item.entry.amount} currency={props.currency} /> ({InvoiceName(t, item.entry.reason)})
              </EntryFirstLine>
              {item.entry.notes && (
                <div className="mt-3 bg-secondary-light border py-3 px-4 text-sm rounded-md">{item.entry.notes}</div>
              )}
            </>
          ) : item.entry.type === "bookingItemAssigned" || item.entry.type === "bookingItemUnassigned" ? (
            <>
              <EntryFirstLine>
                <span className="font-medium">
                  {item.entry.type === "bookingItemAssigned"
                    ? t("booking_traceLog_bookingItemAssigned_label", "Booking item assigned")
                    : t("booking_traceLog_bookingItemUnassigned_label", "Booking item unassigned")}
                </span>
              </EntryFirstLine>
              <div className="text-sm flex items-center gap-3">
                <span>{item.entry.variant}</span>
                <ArrowRight size={16} />
                <span className={cn(item.entry.type === "bookingItemUnassigned" && "line-through")}>
                  {item.entry.identifier}
                </span>
              </div>
            </>
          ) : item.entry.type === "bookingFieldsUpdated" ? (
            <>
              <EntryFirstLine>
                <span className="font-medium">
                  {t("booking_traceLog_bookingFieldsUpdated_label", "Extra fields updated")}
                </span>
              </EntryFirstLine>
              <Bubble className="flex flex-col gap-2">
                {item.entry.newValues.map((value) => {
                  const field = bookingCustomFields?.find((field) => field.id === value.id);
                  if (!field) {
                    return null;
                  }
                  return (
                    <div key={value.id}>
                      <span className="text-muted-foreground">{field.name}</span>
                      <FieldValue type={field.type} value={value.value ?? null} />
                    </div>
                  );
                })}
              </Bubble>
            </>
          ) : null}
        </Entry>
      ))}
    </div>
  );
}

function StatusChangedLogEntry({ entry }: { entry: ApiObjects["StatusChangedTraceLogEntry"] }) {
  const { t } = useTranslation();

  const message: Record<ApiObjects["BookingDto"]["status"], string> = {
    ON_HOLD: t("booking_traceLog_statusChanged_held", "Booking put on hold"),
    UNCONFIRMED: t("booking_traceLog_statusChanged_review", "Booking set for review"),
    PENDING: t("booking_traceLog_statusChanged_confirmed", "Booking confirmed"),
    IN_PROGRESS: t("booking_traceLog_statusChanged_delivered", "Booking delivered"),
    COMPLETED: t("booking_traceLog_statusChanged_returned", "Booking returned"),
    CANCELLED: t("booking_traceLog_statusChanged_cancelled", "Booking cancelled"),
  };

  return (
    <>
      <EntryFirstLine>
        <span className="font-medium">{message[entry.newStatus]}</span>
      </EntryFirstLine>
      <div className="flex items-center gap-3 text-sm mt-1">
        <BookingStatus className="py-1" status={entry.oldStatus} /> <ArrowRight size={16} />{" "}
        <BookingStatus status={entry.newStatus} className="py-1" />
      </div>
    </>
  );
}

function EntryFirstLine(props: PropsWithChildren<{ className?: string }>) {
  return <div className={cn("mt-2", props.className)}>{props.children}</div>;
}

function Entry(props: PropsWithChildren<{ icon: LucideIcon; date: string | null; user: string | null }>) {
  return (
    <div className="flex gap-3 relative pt-9">
      <div>
        <div className="h-10 w-10 rounded-full border flex items-center justify-center bg-white">
          <props.icon size={18} />
        </div>
        <div
          style={{ width: "1px", marginLeft: "-0.5px" }}
          className="bg-gray-400 absolute left-5 top-0 bottom-0 -z-10"
        ></div>
      </div>
      <div>
        {props.children}
        {props.date && props.user && (
          <div className="mt-1 text-muted-foreground text-sm flex gap-2">
            <span>{formatDate(props.date, null, undefined, "date-time")}</span>
            <span>|</span>
            <span>{props.user}</span>
          </div>
        )}
      </div>
    </div>
  );
}

function getCreatedViaTranslation(t: TFunction, createdVia: ApiObjects["BookingDto"]["createdVia"]) {
  switch (createdVia) {
    case "APP":
      return t("common_createdVia_app");
    case "FAST_TRACK":
      return t("common_createdVia_fastTrack");
    case "STORE":
      return t("common_createdVia_store");
    default:
      return createdVia;
  }
}

function getMailTranslation(t: TFunction, mailType: MailType) {
  switch (mailType) {
    case "BookingCancelled":
      return t("booking_traceLog_emailSent_bookingCancelled", "Booking cancelled");
    case "BookingConfirmed":
      return t("booking_traceLog_emailSent_bookingConfirmed", "Booking confirmed");
    case "BookingCreated":
      return t("booking_traceLog_emailSent_bookingCreated", "Booking created");
    case "BookingReceived":
      return t("booking_traceLog_emailSent_bookingReceived", "Booking received");
    case "DeliveryMail":
      return t("booking_traceLog_emailSent_deliveryMail", "Booking delivered");
    case "SingleSignature":
      return t("booking_traceLog_emailSent_document", "Document delivered");
    case "BookingFastTrack":
      return t("booking_traceLog_emailSent_bookingFastTrack", "Fast Track link");
    default:
      return mailType;
  }
}

function Bubble(props: PropsWithChildren<{ className?: string }>) {
  return (
    <div className={cn("mt-3 bg-secondary-light border py-3 px-4 text-sm rounded-md", props.className)}>
      {props.children}
    </div>
  );
}
