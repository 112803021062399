import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";
import { ApiObjects } from "@pulso/api-client";

export function useBookingCustomFieldUpdate() {
  return useMutation({
    mutationFn: async (data: ApiObjects["CustomerFieldUpdateBody"] & { id: string }) => {
      const { id: fieldId, ...body } = data;
      return await api.updateBookingCustomField({ fieldId }, body);
    },
    invalidateKeys: [["booking-custom-fields"]],
    successMessage: "SAVE_SUCCESS",
  });
}
