"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPaymentMethodLabel = getPaymentMethodLabel;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getPaymentMethodLabel(t, method) {
    switch (method) {
        case "CARD":
            return t("common_card");
        case "CASH":
            return t("common_cash");
        case "TRANSFER":
            return t("common_transfer");
        case "PAYPAL":
            return "PayPal";
        case "STRIPE":
            return "Stripe";
        case "REDSYS":
            return "Redsys";
        case "DELAY":
            return t("api_documents_productsVariable_peanlty_delay");
        case "DAMAGE":
            return t("api_documents_productsVariable_peanlty_damage");
        case "LOST":
            return t("api_documents_productsVariable_peanlty_lost");
        case "OTHER":
            return t("api_documents_productsVariable_peanlty_other");
        case "UNKNOWN":
            return "-";
        default:
            return method;
    }
}
